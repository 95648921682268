import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ThemedText } from "src/theme/text";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { formatAmount2 } from "src/helpers/Helpers";

const PriceBox = styled(Box)`
  display: flex;
  align-items: center;
  height: 36px;
  background: radial-gradient(
      99.03% 9902.78% at 0.97% 50%,
      #002421 0%,
      #00524a 46.94%,
      #282828 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  .logo {
    padding-left: 16px;
    padding-right: 28px;
    border-right: 1px solid rgba(255, 255, 255, 0.16);
  }
  .price {
    padding-left: 12px;
  }
`;

export default function PriceM() {
  const PIKOPrice = useGetPikoPrice();
  return (
    <PriceBox className="price">
      <Box display="flex" alignItems="center" className="logo">
        <PIKO width={16} height={16} />
        <ThemedText.Text1Bold fontSize={14} ml="8px">
          PIKO
        </ThemedText.Text1Bold>
      </Box>
      <Box display="flex" alignItems="center" className="price">
        <ThemedText.Text9 fontSize={14} ml="8px">
          Price:
        </ThemedText.Text9>
        <ThemedText.Text1Bold fontSize={14} ml="8px">
          {formatAmount2(PIKOPrice, 2, true)}
        </ThemedText.Text1Bold>
      </Box>
    </PriceBox>
  );
}
