import { PLP_LIST } from "src/constants/tokens";
import {
  useMultipleContractSingleData,
  useSingleCallResult,
} from "src/lib/hooks/multicall";
import { useWeb3Context } from "./web3Context";
import { Interface } from "@ethersproject/abi";
import { abi as PlpABI } from "../abis/PLP.json";
import { abi as PlpManagerAbi } from "../abis/PlpManager.json";
import {
  WHITE_GNS_TOKEN_ADDRESS,
  getNativeToken,
  getTokens,
} from "src/configs/Tokens";
import { useGetPikoPrice } from "./get_price_piko";
import { useEffect, useMemo, useState } from "react";
import { BN, toFromBN } from "src/utils/bn";
import { usePIKOContract, useRewardRouterContract } from "./useContract";
import { useMultipleContractMultipleData } from "src/lib/hooks/useMultipleContractMultipleData";
import { compareAddress } from "src/utils/address";
import axios from "axios";
import { useQueryCircLocked, useQueryInfoHelper } from "./useQueryEarn";
import { abi as RewardRouterABI } from "src/abis/RewardRouter.json";
import { abi as StakingPoolAbi } from "src/abis/StakingPool.json";
import { AddressZero } from "src/constants/address";
import { addresses as ADDRESS } from "src/configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
const PLP_INTERFACE = new Interface(PlpABI);
const PLP_MANAGER_INTERFACE = new Interface(PlpManagerAbi);
const REWOR_INTERFACE = new Interface(RewardRouterABI);
const STAKE_POOL = new Interface(StakingPoolAbi);
const getFeeAmount = async () => {
  const dalyFeeUrl_bsc = "";
  const baseUrl = dalyFeeUrl_bsc;
  return axios.get(baseUrl);
};

export const useBalance_PIKO_BNB_LP = () => {
  const { chainID } = useWeb3Context();
  const PIKO_BNB_LP_addr = ADDRESS[chainID]?.PIKO_BNB_LP;
  const PIKOContract = usePIKOContract();
  const { result } = useSingleCallResult(PIKOContract, "balanceOf", [
    PIKO_BNB_LP_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_PIKO_esPIKO_LP = () => {
  const { chainID } = useWeb3Context();
  const PIKO_esPIKO_LP_addr = ADDRESS[chainID]?.PIKO_esPIKO_LP;
  const PIKOContract = usePIKOContract();
  const { result } = useSingleCallResult(PIKOContract, "balanceOf", [
    PIKO_esPIKO_LP_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_PIKO_staking = () => {
  const { chainID } = useWeb3Context();
  const PIKOStaking_addr = ADDRESS[chainID]?.PIKOStaking;
  const PIKOContract = usePIKOContract();
  const { result } = useSingleCallResult(PIKOContract, "balanceOf", [
    PIKOStaking_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useDataList = () => {
  const tokensArr = useQueryListData();
  const PIKOPrice = useGetPikoPrice();
  // const balance_PIKO_BNB_LP = useBalance_PIKO_BNB_LP();
  // const balance_PIKO_esPIKO_LP = useBalance_PIKO_esPIKO_LP();
  const balance_PIKO_BNB_LP = BN(0);
  const balance_PIKO_esPIKO_LP = BN(0);
  const balance_PIKO_staking = useBalance_PIKO_staking();

  const {
    totalSupply_vePIKO,
    totalStaked_PIKO,
    circulatingSupply,
    balance_PIKO_PIKO_BUSD,
    avgLockTime_PIKO,
    avgLockTime_esPIKO,
    circLocked_esPIKO,
    circLocked_PIKO,
  } = useQueryCircLocked();

  const rank = useQueryInfoHelper();
  // const collateralList = useQeuryUSDCCollateral();

  return useMemo(() => {
    if (
      !tokensArr ||
      // !totalStaked_PIKO ||
      // !totalSupply_vePIKO ||
      // !avgLockTime_PIKO ||
      // !avgLockTime_esPIKO ||
      // !circLocked_esPIKO ||
      // !circLocked_PIKO ||
      // !circulatingSupply ||
      !PIKOPrice
    )
      return;
    return {
      ...tokensArr,
      USDCPrice: 1,
      PIKOPrice: PIKOPrice.toNumber(),

      collateralList: [],
      totalSupply_vePIKO: totalSupply_vePIKO?.toNumber() ?? 0,
      totalStaked_PIKO: totalStaked_PIKO?.toNumber() ?? 0,
      circulatingSupply: circulatingSupply?.toNumber() ?? 0,
      balance_PIKO_PIKO_BUSD: balance_PIKO_PIKO_BUSD
        ? balance_PIKO_PIKO_BUSD.toNumber()
        : 0,
      balance_PIKO_BNB_LP: balance_PIKO_BNB_LP
        ? balance_PIKO_BNB_LP.toNumber()
        : 0,
      balance_PIKO_esPIKO_LP: balance_PIKO_esPIKO_LP
        ? balance_PIKO_esPIKO_LP.toNumber()
        : 0,
      balance_PIKO_staking: balance_PIKO_staking
        ? balance_PIKO_staking.toNumber()
        : 0,
      rank: 1,
      circLocked_PIKO: circLocked_PIKO?.toNumber() ?? 0,
      circLocked_esPIKO: circLocked_esPIKO?.toNumber() ?? 0,
      avgLockTime_esPIKO: avgLockTime_esPIKO?.toNumber() ?? 0,
      avgLockTime_PIKO: avgLockTime_PIKO?.toNumber() ?? 0,
    };
  }, [
    avgLockTime_PIKO,
    avgLockTime_esPIKO,
    balance_PIKO_BNB_LP,
    balance_PIKO_esPIKO_LP,
    balance_PIKO_staking,
    balance_PIKO_PIKO_BUSD,
    circLocked_PIKO,
    circLocked_esPIKO,
    circulatingSupply,
    PIKOPrice,
    tokensArr,
    totalStaked_PIKO,
    totalSupply_vePIKO,
  ]);
};

export const useQueryListData = () => {
  const { chainID, address } = useWeb3Context();
  const plpList = PLP_LIST[chainID];
  const tokens = getTokens(chainID);
  // console.log("chainID", chainID);
  const plp_addr = plpList?.map((plp) => plp.Plp_address);
  const plp_manager_addr = plpList?.map((plp) => plp.PlpManager_address);
  const rewordRouter_addr = plpList?.map(() => ADDRESS[chainID]?.RewardRouter);
  const plp_rewardTracker_addr = plpList?.map(
    (plp) => plp.rewardTracker_address
  );

  const NATIVE_TOKEN = getNativeToken(chainID);
  const PIKOPrice = useGetPikoPrice();
  const RewardRouter = useRewardRouterContract();
  // const feeAmounts = usePromise();
  const { feeaddr, feeParam } = useMemo(() => {
    const feeaddr: string[] = [];
    const feeParam: any = [];

    plp_addr?.forEach((x) => {
      feeaddr.push(x);
      feeaddr.push(x);
      feeParam.push([[7, 1]]);
      feeParam.push([[1, 1]]);
    });
    return {
      feeaddr,
      feeParam,
    };
  }, [plp_addr]);
  // const getPoolFees = useMultipleContractMultipleData(
  //   feeaddr,
  //   PLP_INTERFACE,
  //   "getFeeAmount",
  //   feeParam
  // );
  const { result: stakedPLP } = useSingleCallResult(
    RewardRouter,
    "stakedPlpnAmount"
  );
  const poolInfos = useMultipleContractSingleData(
    plp_manager_addr,
    PLP_MANAGER_INTERFACE,
    "getPoolInfo"
  );
  const poolAmount = useMultipleContractSingleData(
    plp_rewardTracker_addr,
    STAKE_POOL,
    "totalDepositSupply"
  );
  const tokenLists = useMultipleContractSingleData(
    plp_manager_addr,
    PLP_MANAGER_INTERFACE,
    "getPoolTokenList"
  );
  const plpBalanceAll = useMultipleContractSingleData(
    plp_addr,
    PLP_INTERFACE,
    "balanceOf",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );
  const plpBalanceStakedAll = useMultipleContractSingleData(
    plp_rewardTracker_addr,
    PLP_INTERFACE,
    "balanceOf",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );

  // const claimableUSDCList = useMultipleContractSingleData(
  //   rewordRouter_addr,
  //   REWOR_INTERFACE,
  //   "claimableUSDCListForAccount",
  //   [address ? address : PLACEHOLDER_ACCOUNT]
  // );
  // const claimablesPIKOList = useMultipleContractSingleData(
  //   rewordRouter_addr,
  //   REWOR_INTERFACE,
  //   "claimablesPIKOListForAccount",
  //   [address ? address : PLACEHOLDER_ACCOUNT]
  // );
  // const plpReword = useMemo(() => {
  //   return plp_addr.reduce((pre: any, curr, index: number) => {
  //     const { result: claimableUSDC } = claimableUSDCList[index];
  //     const { result: claimablesPIKO } = claimablesPIKOList[index];
  //     if (!claimableUSDC || !claimablesPIKO || !curr) return;
  //     const USDCIndex = claimableUSDC[0].findIndex((r: any) =>
  //       compareAddress(curr, r)
  //     );
  //     const PIKOIndex = claimableUSDC[0].findIndex((r: any) =>
  //       compareAddress(curr, r)
  //     );
  //     if (USDCIndex == -1 && PIKOIndex == -1) return pre;
  //     pre[curr.toLocaleLowerCase()] = {
  //       claimableUSDC: toFromBN(claimableUSDC[1][USDCIndex]).toFixed(),
  //       claimablesPIKO: toFromBN(claimablesPIKO[1][PIKOIndex]).toFixed(),
  //     };
  //     return pre;
  //   }, {});
  // }, [claimablesPIKOList, claimableUSDCList, plp_addr]);
  const { managerAddr, tokenAddr } = useMemo(() => {
    const managerAddr: string[] = [];
    const tokenAddr: any = [];
    plp_manager_addr?.map((manager, index) => {
      const { result } = tokenLists[index];
      if (!result) return;
      const data = result[0];
      data?.map((x: any) => {
        managerAddr.push(manager);
        tokenAddr.push([[x]]);
      });
    });
    return {
      managerAddr,
      tokenAddr,
    };
  }, [plp_manager_addr, tokenLists]);
  const getPoolTokenInfos = useMultipleContractMultipleData(
    !!managerAddr.length ? managerAddr : [undefined],
    PLP_MANAGER_INTERFACE,
    "getPoolTokenInfo",
    !!tokenAddr.length ? tokenAddr : undefined
  );

  const chainInfoArr: any = useMemo(() => {
    return managerAddr?.reduce((pre: any, curr, index) => {
      if (!getPoolTokenInfos[index][0]) return;
      const { result } = getPoolTokenInfos[index][0];
      if (!result) return;
      const tokenInfo = result[0];
      const addr = tokenAddr[index][0][0];
      const currTokenArr = tokens.filter((token: any) =>
        compareAddress(addr, token.address)
      );
      const currToken = currTokenArr[0];
      const tokenWeight = toFromBN(tokenInfo[0], 6).toNumber();
      const utilization = toFromBN(tokenInfo[1], 6).toNumber();
      const price = toFromBN(tokenInfo[3], 30).toNumber();

      let poolNum = BN(0);
      let poolAmount = 0;
      if (tokenInfo[2].gt(0)) {
        poolNum = toFromBN(tokenInfo[2], currToken.decimals);
        poolAmount = poolNum.times(price).toNumber();
      } else {
        poolAmount = 0;
      }
      const data = {
        tokenWeight,
        price,
        utilization,
        poolNum: poolNum.toNumber(),
        poolAmount,
        ...currToken,
      };
      const cur = curr.toLocaleLowerCase();
      if (pre[cur]) {
        pre[cur].totalAmount += poolAmount;
        pre[cur].chainInfoArr.push(data);
      } else {
        pre[cur] = { chainInfoArr: [data], totalAmount: poolAmount };
      }
      return pre;
    }, {});
  }, [getPoolTokenInfos, managerAddr, tokenAddr, tokens]);
  const poolData = useMemo(() => {
    return plpList?.reduce((pre: any, curr, index) => {
      if (!poolInfos[index]) return;
      const {
        result: poolInfo,
        loading: poolInfoLoading,
        valid: poolInfoValid,
      } = poolInfos[index];
      if (!tokenLists[index]) return;

      const {
        result: tokenList,
        loading: tokenListLoading,
        valid: tokenListValid,
      } = tokenLists[index];

      if (poolInfoLoading || tokenListLoading) return;

      if (!poolInfoValid || !tokenListValid) return;
      if (!poolInfo || !tokenList) return;
      const pool = poolInfo[0];
      const totalSupply = toFromBN(pool[2]).toFixed();
      const marketCap = toFromBN(pool[0], 30).toFixed();
      // 测试环境 没有价格默认显示为1
      let LP_Price: any = 1;
      if (pool[0].gt(0)) {
        LP_Price = BN(pool[0].div(pool[2])?.toString())
          .div(BN(10).pow(12))
          .toFixed();
      }
      const usdxSupply = pool[3];
      const token = tokenList[0];
      const cur = curr.PlpManager_address.toLocaleLowerCase();
      pre[cur] = {
        totalSupply,
        marketCap,
        price: LP_Price,
        usdxSupply,
        tokenList: token,
      };
      return pre;
    }, {});
  }, [plpList, poolInfos, tokenLists]);

  const plpBalanceAndSake = useMemo(() => {
    return plp_addr.reduce<{
      [key: string]: {
        plpBalance: string;
        plpBalanceStaked: string;
      };
    }>((pre: any, curr, index) => {
      const plp = curr?.toLocaleLowerCase();
      const { result } = plpBalanceAll[index];
      const { result: stake } = plpBalanceStakedAll[index];
      if (!result || !stake) return;
      pre[plp] = {
        plpBalance: toFromBN(result[0]).toFixed(),
        plpBalanceStaked: toFromBN(stake[0]).toFixed(),
      };
      return pre;
    }, {});
  }, [plpBalanceAll, plpBalanceStakedAll, plp_addr]);
  const AllPoolsInfo = useMemo(() => {
    if (!stakedPLP) return;
    return plp_addr.reduce<{
      [key: string]: {
        totalStaked: string;
        stakingPool: string;
        totalShareStaked: string;
      };
    }>((pre: any, curr, indexs: number) => {
      const { result } = poolAmount[indexs];
      if (!result) return;
      const index = stakedPLP[0].findIndex((plp: string) =>
        compareAddress(plp, curr)
      );

      if (index != -1) {
        const cur = curr.toLocaleLowerCase();
        pre[cur] = {
          totalStaked: toFromBN(result[0]).toFixed(),
          totalShareStaked: toFromBN(stakedPLP[1][index]).toFixed(),
          stakingPool: toFromBN(stakedPLP[2][index]).toFixed(),
        };
      }
      return pre;
    }, {});
  }, [plp_addr, poolAmount, stakedPLP]);

  const getCalcMinFee = useMemo(() => {
    return feeaddr?.reduce((pre: any, curr) => {
      // const { result } = getPoolFees[index][0];
      // if (!curr || !result) return pre;
      const cur = curr?.toLocaleLowerCase();
      if (pre[cur]) {
        pre[cur] = {
          ...pre[cur],
          feeAmount2: 1,
          feeAmout: 1,
        };
      } else {
        pre[cur] = {
          feeAmount1: 1,
        };
      }
      return pre;
    }, {});
  }, [feeaddr]);

  const ALLData = useMemo(() => {
    if (
      !chainInfoArr ||
      !poolData ||
      !AllPoolsInfo ||
      !getCalcMinFee ||
      !PIKOPrice ||
      !plpBalanceAndSake
      // !claimableUSDCList ||
      // !claimablesPIKOList ||
      // !plpReword
    )
      return;
    return plpList?.reduce(
      (pre: any, curr) => {
        const plpManager = curr.PlpManager_address.toLocaleLowerCase();
        const plp = curr.Plp_address.toLocaleLowerCase();
        const infoArr = chainInfoArr[plpManager];
        const poolDatas = poolData[plpManager];
        const { totalStaked, stakingPool, totalShareStaked } =
          AllPoolsInfo[plp] ?? {};
        const fees = getCalcMinFee[plp];
        const { plpBalance, plpBalanceStaked } = plpBalanceAndSake[plp];
        // const { claimableUSDC, claimablesPIKO } = plpReword[plp];
        const queryFees = 33;
        const tokenIfo = infoArr?.chainInfoArr?.map((item: any) => {
          const poolAmount = item.poolAmount;
          const poolsum = infoArr.totalAmount;
          const tokenAddr = item.address.toLocaleLowerCase();

          if (item.isWrapped) {
            const zero = AddressZero.toLocaleLowerCase();
            if (!pre.tokensMap[zero]) {
              pre.allToken.push({
                plp_type: curr.name,
                ...NATIVE_TOKEN,
              });
              pre.tokensMap[zero] = {
                plp_type: curr.name,
                ...NATIVE_TOKEN,
              };
            }
          }
          if (curr.isGNS) {
            const gnsTokens = WHITE_GNS_TOKEN_ADDRESS(chainID);
            gnsTokens?.map((xitem: any) => {
              if (!xitem.address) return;
              const GNStokenAddr = xitem.address.toLocaleLowerCase();
              if (!pre.tokensMap[GNStokenAddr]) {
                pre.allToken.push({
                  plp_type: curr.name,
                  ...xitem,
                  isGNS: true,
                });
                pre.tokensMap[GNStokenAddr] = {
                  plp_type: curr.name,
                  ...xitem,
                  isGNS: true,
                };
              }
            });
          }

          if (!pre.tokensMap[tokenAddr]) {
            pre.tokensMap[tokenAddr] = {
              plp_type: curr.name,
              ...item,
            };
            pre.allToken.push({
              plp_type: curr.name,
              ...item,
            });
          }

          const tokenWeight2 = BN(poolAmount).div(poolsum).toNumber();
          return {
            ...item,
            tokenWeight2,
          };
        });

        const apr_PIKO_plp = BN(stakingPool)
          .times(3600)
          .times(24)
          .times(365)
          .times(PIKOPrice)
          .div(BN(totalShareStaked).times(poolDatas.price));

        let apr_USDC_plp = 0;
        if (curr.isOld) {
          apr_USDC_plp =
            (queryFees * 365) / (1 * poolDatas.totalSupply * poolDatas.price);
        } else {
          // apr_USDC_plp =
          //   (((Number(ethers.utils.formatUnits(fees.feeAmout, 18)) / 7) * 365) /
          //     (1 * poolDatas.totalSupply * poolDatas.price)) *
          //   0.6;
          apr_USDC_plp =
            (((Number(1000000) / 7) * 365) /
              (1 * poolDatas.totalSupply * poolDatas.price)) *
            0.6;
        }
        const apr = apr_PIKO_plp.plus(apr_USDC_plp).toNumber();
        const data = {
          ...curr,
          ...poolDatas,
          chainInfoArr: tokenIfo,
          address: curr.Plp_address,
          claimableUSDC: "0",
          claimablesPIKO: "0",
          totalStaked_plp: totalStaked,
          apr_PIKO_plp: apr_PIKO_plp.toFixed(),
          apr_USDC_plp: apr_USDC_plp,
          apr,
          plpBalance,
          plpBalanceStaked,
        };
        pre.tokensArr.push(data);
        pre.PLPMAP[curr.name] = data;
        return pre;
      },
      { tokensMap: {}, allToken: [], tokensArr: [], PLPMAP: {} }
    );
  }, [
    AllPoolsInfo,
    NATIVE_TOKEN,
    chainID,
    chainInfoArr,
    PIKOPrice,
    plpBalanceAndSake,
    plpList,
    getCalcMinFee,
    poolData,
  ]);
  return ALLData;
};

export const usePromise = () => {
  const [state, setState] = useState<undefined | any>();

  useEffect(() => {
    getFeeAmount().then((res) => {
      setState(res.data || {});
    });
  }, []);

  return {
    data: state,
  };
};
