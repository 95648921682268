import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip2 from "src/components/Tooltip/Tooltip";
export const HtmlTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "14px",
    color: "rgba(238, 242, 249, 0.5)",
    width: "380px",
    background: "#1A191E",
    border: "1px solid rgba(255, 255, 255, 0.08)",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.4)",
    borderRadius: "4px",
    padding: "8px 16px",
  },
}));

export default function Tooltips({
  renderContent,
  children,
  className = "",
  position = "bottom",
}: any) {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  return (
    <>
      {isVerySmallScreen ? (
        <Tooltip2
          className={className}
          handle={children}
          position={position}
          renderContent={renderContent}
        ></Tooltip2>
      ) : (
        <HtmlTooltip className={className} title={renderContent}>
          <Box width={"fit-content"} className={className}>
            {children}
          </Box>
        </HtmlTooltip>
      )}
    </>
  );
}
