export const RANK_LIST = { 1: "F", 2: "E", 3: "D", 4: "C", 5: "B", 6: "A", 7: "S", 8: "SS", 8: "SSS" }
export const TYPE_CODE = {
  "0": "Mint PID",
  "1": "Open/Close Position",
  "11": "Open/Close Position (Invitee)",
  "101": "Open/Close Position",
  "111": "Open/Close Position (Invitee)",
  "2": "Swap",
  "12": "Swap (Invitee)",
  "3": "Mint PLP",
  "13": "MInt PLP (Invitee)",
  "103": "Redeem PLP",
  "113": "Redeem PLP (Invitee)",
  "999": "Monthly Deduction",
  "9": "Burn esPIKO",
}