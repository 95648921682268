import ZK from "src/assets/images/chains/zkSync.svg";
import Mantle from "src/assets/images/chains/Mantle.svg";
export type AddressEnvMap = { [chainId: number]: number };
export enum SupportedChainId {
  BSCTEST = 97,
  ARB_TEST = 421613,
  BSCMAINNET = 56,
  ARBITRUM = 42161,
  ZKMAINNET = 324,
  ZKTEST = 280,
  MANTTEST = 5001,
}

export const NETWORK_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]: "https://goerli.arbiscan.io",
  [SupportedChainId.BSCTEST]: "https://testnet.bscscan.com",

  [SupportedChainId.ARBITRUM]: "https://arbiscan.io",
  [SupportedChainId.BSCMAINNET]: "https://bscscan.com",

  [SupportedChainId.ZKMAINNET]: "https://explorer.zksync.io",
  [SupportedChainId.ZKTEST]: "https://goerli.explorer.zksync.io",

  [SupportedChainId.MANTTEST]: "https://explorer.testnet.mantle.xyz",
};

export const RPC_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]:
    "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
  [SupportedChainId.BSCTEST]: "https://data-seed-prebsc-1-s1.binance.org:8545",
  [SupportedChainId.ARBITRUM]:
    "https://endpoints.omniatech.io/v1/arbitrum/one/public",
  [SupportedChainId.BSCMAINNET]:
    "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
  [SupportedChainId.ZKMAINNET]: "https://zksync2-mainnet.zksync.io",
  [SupportedChainId.ZKTEST]: "https://testnet.era.zksync.dev",
  [SupportedChainId.MANTTEST]: "https://rpc.testnet.mantle.xyz",
};

export const CHAINNAME_LIST = {
  [SupportedChainId.ZKMAINNET]: {
    chainName: "zkSync Era",
    nativeCurrency: "ETH",
    icon: ZK,
  },
  [SupportedChainId.ZKTEST]: {
    chainName: "zkSync Era Testnet",
    nativeCurrency: "ETH",
    icon: ZK,
  },
  [SupportedChainId.MANTTEST]: {
    chainName: "Mantle Testnet",
    nativeCurrency: "MNT",
    icon: Mantle,
  },
};
