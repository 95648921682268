import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const BoostBtn = styled(Button)({
  height: "28px",
  lineHeight: "28px",
  maxHeight: "28px",
  width: "auto",
  color: "#14D1B2",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "rgba(20, 209, 178, 0.2)",
  borderRadius: "4px",
  border: "none",
  "&:hover": {
    border: "1px solid",
    borderColor: "#14D1B2",
    color: "#14D1B2",
  },
});
