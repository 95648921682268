import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";

const TabBox = styled(Box)`
  .tabItem {
    padding: 8px 12px;
    cursor: pointer;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .activeTabItem {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    svg {
      path {
        fill: #14d1b2;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    display: flex;
    width: 100%;
    .tabItem {
      flex: 1;
      display: flex;
      justify-content: center;
      :not(:first-child) {
        margin-top: 0;
      }
    }
  }
`;

type dataPros = {
  curr?: number;
  setCurr?: any;
  tabList?: any;
};

const LeftTab = ({ curr, tabList, setCurr }: dataPros) => {
  return (
    <TabBox className="tabBox">
      {tabList.map((tab: any, index: number) => (
        <Box
          display="flex"
          alignItems="center"
          className={`tabItem pointer ${curr == index && "activeTabItem"}`}
          key={index}
          onClick={() => setCurr(index)}
        >
          {tab.icon}
          <div className="flex-1">
            <ThemedText.Text1Medium fontSize={16} ml={10} className="text">
              <Trans>{tab.title}</Trans>
            </ThemedText.Text1Medium>
          </div>
        </Box>
      ))}
    </TabBox>
  );
};

export default LeftTab;
