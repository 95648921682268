import { getContractAddress } from "src/Addresses";

export const getVaultAddress = (chainID, plpName) => {
  let vaultAddress1 = getContractAddress(chainID, "Vault");
  if (plpName == 'PLP-2') {
    vaultAddress1 = getContractAddress(chainID, "Vault_PLP_2");
  } else if (plpName == 'PLP-1p') {
    vaultAddress1 = getContractAddress(chainID, "Vault_PLP_1p");
  } else if (plpName == 'PLP-3') {
    vaultAddress1 = getContractAddress(chainID, "Vault_PLP_3");
  }
  return vaultAddress1
}

export const getPositionRouterAddress = (chainID, plpName) => {
  let positionRouterAddress1 = getContractAddress(chainID, "PositionRouter");
  if (plpName == 'PLP-2') {
    positionRouterAddress1 = getContractAddress(chainID, "PositionRouter_PLP_2");
  } else if (plpName == 'PLP-1p') {
    positionRouterAddress1 = getContractAddress(chainID, "PositionRouter_PLP_1p");
  }
  return positionRouterAddress1
}

export const getOrderBookAddress = (chainID, plpName) => {
  let orderBookAddress1 = getContractAddress(chainID, "OrderBook");
  if (plpName == 'PLP-2') {
    orderBookAddress1 = getContractAddress(chainID, "OrderBook_PLP_2");
  } else if (plpName == 'PLP-1p') {
    orderBookAddress1 = getContractAddress(chainID, "OrderBook_PLP_1p");
  }
  return orderBookAddress1
}

export const getRouterAddress = (chainID, plpName) => {
  let routerAddress1 = getContractAddress(chainID, "Router");
  if (plpName == 'PLP-2') {
    routerAddress1 = getContractAddress(chainID, "Router_PLP_2");
  } else if (plpName == 'PLP-1p') {
    routerAddress1 = getContractAddress(chainID, "Router_PLP_1p");
  }
  return routerAddress1
}

export const getPositionManagerAddress = (chainID, plpName) => {
  let positionManagerAddress1 = getContractAddress(chainId, "PositionManager");

  if (plpName == 'PLP-2') {
    positionManagerAddress1 = getContractAddress(chainID, "PositionManager_PLP_2");
  } else if (plpName == 'PLP-1p') {
    positionManagerAddress1 = getContractAddress(chainID, "PositionManager_PLP_1p");
  }
  return positionManagerAddress1
}

export const getPlpAddress = (chainID, plpName) => {
  let Plp1Address = getContractAddress(chainID, "PLP");
  if (plpName == 'PLP-2') {
    Plp1Address = getContractAddress(chainID, "PLP_2");
  } else if (plpName == 'PLP-1p') {
    Plp1Address = getContractAddress(chainID, "PLP_1p");
  }
  return Plp1Address
}

export const getPlpManagerAddress = (chainID, plpName) => {
  let plpManagerAddress1 = getContractAddress(chainID, "PlpManager");
  if (plpName == 'PLP-2') {
    plpManagerAddress1 = getContractAddress(chainID, "PlpManager_PLP_2");
  } else if (plpName == 'PLP-1p') {
    plpManagerAddress1 = getContractAddress(chainID, "PlpManager_PLP_1p");
  }
  return plpManagerAddress1
}