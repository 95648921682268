import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import EPointProgress from "./EPointProgress";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import Dots from "src/components/Dots";
import { ReactComponent as BoostIcon } from "src/assets/images/pid/boost.svg";
import { BoostBtn } from "src/components/Button/BoostBtn";
import CountDown from "./CountDown";
import { useBasicInfo } from "src/hooks/useQueryPID";
import { formatAmount2 } from "src/helpers/Helpers";
import { RANK_LIST } from "src/helpers/rankConstants";
import { useState } from "react";
import BoostDialog from "../BoostDialog";
import { useVePIKOBalance } from "src/hooks/useQueryEarn";
import LevelTooltip from "./LevelTooltip";

const AccountBox = styled.div`
  .accountInfo {
    margin-top: 60px;
  }
  @media only screen and (max-width: 1080px) {
    .accountInfo {
      margin-top: 20px;

      .infoItem {
        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }
  }
`;

export default function AccountInfo() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const vePIKOBalance = useVePIKOBalance();
  const baseInfo = useBasicInfo();
  const [showBoost, setShowBoost] = useState<boolean>(false);
  const handleClose = () => {
    setShowBoost(false);
  };
  return (
    <AccountBox className="account-view">
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems="flex-start"
        justifyContent="space-between"
        className="accountInfo"
      >
        <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Trading Volumes</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <ThemedText.Text1Bold fontSize={18} mt="12px">
            ${formatAmount2(baseInfo?.userSizeSum, 2, true)}
          </ThemedText.Text1Bold>
        </div>
        <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Points</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <Box display="flex" alignItems="center" mt="12px" className="">
            <ThemedText.Text1Bold fontSize={18}>
              {formatAmount2(baseInfo?.score, 0, true)}
            </ThemedText.Text1Bold>
            <Box ml="32px">
              <BoostBtn
                onClick={() => {
                  setShowBoost(true);
                }}
              >
                <BoostIcon />
                <Box ml="8px">BOOST</Box>
              </BoostBtn>
            </Box>
          </Box>
        </div>
        <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Level</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text5 fontSize={12} ml="16px">
              <CountDown />
            </ThemedText.Text5>
          </Box>
          <Box display="flex" alignItems="center" mt="12px" className="">
            <ThemedText.Text1Bold fontSize={18}>
              {RANK_LIST[baseInfo?.rank]}
            </ThemedText.Text1Bold>
            <Box display="inline-flex" ml="10px" className="pointer">
              <LevelTooltip />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mt="8px" className="">
            <EPointProgress />
          </Box>
        </div>
        {/* <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>vePIKO</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <Box mt="12px" className="">
            <ThemedText.Text9Bold fontSize={18} mt="12px">
              {formatAmount2(vePIKOBalance, 4, true)}
            </ThemedText.Text9Bold>
            <Box display="flex" flexDirection="column" mt="12px" className="">
              <VePIKOProgress />
            </Box>
          </Box>
        </div> */}
      </Box>
      <BoostDialog open={showBoost} handleClose={handleClose} />
    </AccountBox>
  );
}
