import { addresses as ADDRESS } from "../configs/constants";
import { SupportedChainId } from "./chainENV/chains.base";
import { getTokenBySymbol } from "src/configs/Tokens";
import PLP_1 from "src/assets/images/tokens/PLP-β.svg";
// import PLP_2 from "src/assets/images/tokens/PLP-α.svg";

//  PLP 默认查询  数据列表
type ListProps = {
  name: PLP_TYPES;
  icon: any;
  top: string;
  Plp_address: string;
  PlpManager_address: string;
  rewardTracker_address: string;
  isOld?: boolean; // 是否是旧版elp
  vault_address: string;
  positionRouter_address: string;
  orderBook_address: string;
  router_address: string;
  positionManager_address: string;
  isGNS?: boolean; // 是否是gns 模式
  routerSign_address?: string; // 新版签名模式
  vaultUtil_addr?: string; // 新版查询
};

type LpListProps = {
  name: string;
  stakeToken: string;
  isLp: boolean;
  pool: string;
  imgUrl: any;
  stablecoin: boolean;
  rewardToken: string;
  token0?: {
    address: string;
    decimals: number;
  };
  token1?: {
    address: string;
    decimals: number;
  };
};

export enum PLP_TYPES {
  PLP_1 = "PLP-β",
  PLP_2 = "PLP-α",
}
export const PLP_LIST: { [key: number]: ListProps[] } = {
  [SupportedChainId.ZKMAINNET]: [
    {
      name: PLP_TYPES.PLP_1,
      icon: PLP_1,
      top: "Top 5 ETF",
      Plp_address: ADDRESS[SupportedChainId.ZKMAINNET].PLP_β,
      vault_address: ADDRESS[SupportedChainId.ZKMAINNET].Vault_PLP_β,
      vaultUtil_addr: ADDRESS[SupportedChainId.ZKMAINNET].VaultUtils_PLP_β,
      PlpManager_address: ADDRESS[SupportedChainId.ZKMAINNET].PlpManager_PLP_β,
      rewardTracker_address:
        ADDRESS[SupportedChainId.ZKMAINNET].RewardPool_PLP_β,
      positionRouter_address:
        ADDRESS[SupportedChainId.ZKMAINNET].PositionRouter_PLP_β,
      orderBook_address: ADDRESS[SupportedChainId.ZKMAINNET].OrderBook_PLP_β,
      router_address: ADDRESS[SupportedChainId.ZKMAINNET].Router_PLP_β,
      positionManager_address:
        ADDRESS[SupportedChainId.ZKMAINNET].PositionManager_PLP_β,
      routerSign_address: ADDRESS[SupportedChainId.ZKMAINNET].RouterSign_PLP_β,
    },
  ],
  [SupportedChainId.ZKTEST]: [
    {
      name: PLP_TYPES.PLP_1,
      icon: PLP_1,
      top: "Top 5 ETF",
      Plp_address: ADDRESS[SupportedChainId.ZKTEST].PLP_β,
      vault_address: ADDRESS[SupportedChainId.ZKTEST].Vault_PLP_β,
      vaultUtil_addr: ADDRESS[SupportedChainId.ZKTEST].VaultUtils_PLP_β,
      PlpManager_address: ADDRESS[SupportedChainId.ZKTEST].PlpManager_PLP_β,
      rewardTracker_address: ADDRESS[SupportedChainId.ZKTEST].RewardPool_PLP_β,
      positionRouter_address:
        ADDRESS[SupportedChainId.ZKTEST].PositionRouter_PLP_β,
      orderBook_address: ADDRESS[SupportedChainId.ZKTEST].OrderBook_PLP_β,
      router_address: ADDRESS[SupportedChainId.ZKTEST].Router_PLP_β,
      positionManager_address:
        ADDRESS[SupportedChainId.ZKTEST].PositionManager_PLP_β,
      routerSign_address: ADDRESS[SupportedChainId.ZKTEST].RouterSign_PLP_β,
    },
  ],
  [SupportedChainId.MANTTEST]: [
    {
      name: PLP_TYPES.PLP_1,
      icon: PLP_1,
      top: "Top 5 ETF",
      Plp_address: ADDRESS[SupportedChainId.MANTTEST].PLP_β,
      vault_address: ADDRESS[SupportedChainId.MANTTEST].Vault_PLP_β,
      vaultUtil_addr: ADDRESS[SupportedChainId.MANTTEST].VaultUtils_PLP_β,
      PlpManager_address: ADDRESS[SupportedChainId.MANTTEST].PlpManager_PLP_β,
      rewardTracker_address:
        ADDRESS[SupportedChainId.MANTTEST].RewardTracker_PLP_β,
      positionRouter_address:
        ADDRESS[SupportedChainId.MANTTEST].PositionRouter_PLP_β,
      orderBook_address: ADDRESS[SupportedChainId.MANTTEST].OrderBook_PLP_β,
      router_address: ADDRESS[SupportedChainId.MANTTEST].Router_PLP_β,
      positionManager_address:
        ADDRESS[SupportedChainId.MANTTEST].PositionManager_PLP_β,
      routerSign_address: ADDRESS[SupportedChainId.MANTTEST].RouterSign_PLP_β,
    },
  ],
};

export const STAKE_LP_LIST: { [key: number]: LpListProps[] } = {
  [SupportedChainId.ARB_TEST]: [],
};

export const PAIR_DETAILS: {
  [key: number]: {
    [lp: string]:
      | {
          token0: {
            address: string;
            decimals: number;
            name: string;
          };
          token1: {
            address: string;
            decimals: number;
            name: string;
          };
        }
      | undefined;
  };
} = {
  [SupportedChainId.ZKTEST]: {
    "": {
      token0: {
        address: "",
        decimals: 18,
        name: "weth",
      },
      token1: {
        address: "",
        decimals: 18,
        name: "piko",
      },
    },
  },
};

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WBNB"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [SupportedChainId.ZKTEST]: [
    "ETH",
    "BTC",
    "BNB",
    "DOGE",
    "ARB",
    "LINK",
    "MATIC",
    "DOT",
  ],
  [SupportedChainId.ZKMAINNET]: ["ETH", "BTC", "USDC"],
  [SupportedChainId.MANTTEST]: [
    "ETH",
    "BTC",
    "BNB",
    "DOGE",
    "ARB",
    "LINK",
    "MATIC",
    "DOT",
  ],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;

  return (
    (token.isStable ||
      AVAILABLE_CHART_TOKENS[chainId]?.includes(
        getNormalizedTokenSymbol(tokenSymbol)
      )) ??
    false
  );
}
