interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  280: {
    NATIVE_TOKEN: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
    WETH: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
    ETH: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
    BTC: "0xF3F37c9923F8AcDe2CACD81AfE985fd7b279bE16",
    USDT: "0x0519112Ef3720a2f8bBC4587dd38773B0D013eE9",
    USDC: "0xC64101683Af0CcA47c38d3Ab0dA2969aEb0833bB",
    DAI: "0xeA1d91484A51769e444fB13f8dCB8F419817A7Ea",
    VaultPriceFeedV3Fast: "0x3198463F1FA4737d21d73EffC888f058788d43CA",
    //TOKENS
    PIKO: "0x40073eA329079b4e89EbaDC52D21479Db792d854",
    esPIKO: "0xbE2bA2d42080561D709C5548DA58f44CdB5e3D46",

    // Common Utils
    ServerPriceFeed: "0x937fac9Dfb2C8d510A0F116cDa8d5A72D5F00070",
    VaultPriceFeed: "0x9cdBe190e67BFa143671782CADE6ac84a07C88fa",

    //Reader
    OrderBookReader: "0x348CF6C92cB74230c126dfAcd2639BDeB3904Bbc",
    VaultReader: "0x1C523C6B86F2Aa638d13cB482ebe8936987a4992",
    Reader: "0x3453CC80e6D10Fa5defCF38850F9624C9c672365",
    PositionReader: "0xf05B29ef48c431801d7d528b7D746c8fF8D05b18",

    // fee
    RewardRouter: "0x73e9662488d351A56269089FD472951ECDe318A9",
    FeeRouter: "0x62548BF26Fb4778d362c8C5A5b271966b1F83d63",
    UserFeeResv: "0x1Eb0552D1A5165cC535779C0DCCe64ce7961F626",

    //DAO
    Treasury: "0xf0425A397Fa8E7740192eC368C773E899E082E49",
    TreasuryResv: "0x5cC8099F4c24133389F67A6FcCb86187795abe12",
    InstVesting: "0x18A203cB32318F407753bAB108A2AC6e92e9ACf4",
    Bond: "0x07639d69566b7a85b218e1f9d71f35681D1F0483",

    // PID
    PID: "0x18d49A38d7C16F2Fa6CeBe9942A8774359DddE53",
    PIDLogic: "0x2FE30C090CEDd843536f966A10bEda81c06a8245",
    InfoHelper: "0xac71e8E20F086c7959ecc9584c86b1642dcaD488",

    //PLP
    PLP_β: "0xc23119B1B6622bfBb6e4B3d27DB8980ff0b12C9e",
    Vault_PLP_β: "0xf048f78E4A42F84d9699B9792a80BD298E376ec8",
    VaultUtils_PLP_β: "0x16e3722F0C744B157745E76cFc5b30eEeF5c4DC4",
    VaultStorage_PLP_β: "0xb3B3d1098ad33225C811e083A000A435606aA575",
    RouterSign_PLP_β: "0x0DF7Bcc004E0920613Cb84905F2b095149C18184",
    PlpManager_PLP_β: "0x789D3459C4Eb8c2645EacFCC921A2045F736954D",
    OrderBook_PLP_β: "0x9A264D3a6B179824f0e6c5AdFA3909f82b076aA8",
    PositionRouter_PLP_β: "0x1f8B47df8760a9293cde8408e8F2e757289F6B60",
    RewardPool_PLP_β: "0xD7Ae6B7cCD79429E705CBC7DFff2E11b9c319D27",

    // Activity
    BurnToScore: "0x732E5d40a5C17A82505bA9DA6333749a3C795c57",
    InviteToEarn: "0xF62df9a75a08c8Ea5fbBAfE7dA4B10E414E4e1D3",
    TradeRebate: "0x8184b2D8C51C6CAB473Ea818A944DF41C330b695",

    // TestOnly

    TwapPrice: "0xFF9fd97D5EBb1085ff6f6e12f0ada919D4696aa0",
    faucet: "0xC0a0CD009d2E2B5b6644ED3031573a8e71EfA019",

    // Contract
    randomSource: "0xb15E20d68437A57b0536CE15dE4781a1E47a40A7",
    NFTUtils: "0xB8b6BCD98Bc6c453EaE071FBA9D1C177b194448F",
    Timelock: "0x1749D555a90b4F62f02510BA86170Ca7a6Da3855",

    // stake espnk and pnk
    vePIKO: "0xBa75F8c842F6b88545267A19127d4A9008c8cF60",
    veesPIKO: "0xF3b5330dA2CBf66cD593C0452ec077fC3c0D8937",
    totalVeToken: "0xBE05deD6b24F45Ca8Cfd856b56a321FFF8A3284c",
    vePIKO_stakeHelper: "0xd9A4019607407EdAab40DaBf13185e2c1dfb5Fa6",
    veesPIKO_stakeHelper: "0xa757cE1974c80498E0089ccBACc45814ADf7cb85",
    TokenVesting: "0x6b8DCd386F61E875b66fdc76bcB9DA70e733C326",
    DisFee: "0x12433725614EEF7993b7718FD2841C3e91Ef7F17",
    DisToken: "0x92CbD7F5E2bd1D965ADE0eB31AA2D87E515d8E0D",

    LpManager: "0x8840C0B6D29f43D1e22bdd67d162a2Fc63B8023c",
    PLP: "0x7a6Ed37C4690F930640F92379FD2Be7fD32f6e03",

    Vault: "0x07da10649555608fA24a450F518e730E223FbAB9",
    VaultUtils: "0xA7712B6845051BB3be21e1995253664cd80dA7Ed",
    VaultStorage: "0x2eAb3B4dB41a9D970b59459490d0e6BB0Db20B6F",
    RouterSign: "0x302c60E802848fE1C8dfc89AbA3C3eeEBaCe3F9F",
    PlpManager: "0x603C9F8a7c807D6e8EEfd6875678971B59ad4491",
    Router: "0x7E923De39CC56Ee26030D7406e1B766F4c668Adf",
    PositionRouter: "0x3fC60D96a3735Bdd433DA41Aa978F55C30F29904",
    RewardTracker: "0x98b63F268A3D03BB4B9B1E75206A30d1e0416382",
    OrderBook: "0x44111682420F634dBA24778271914C3357299Ab8",
    PositionManager: "0x5aAAE46594D4f0518E03DEa1b6D4D291B4de38AF",

    PnkLp: "0x566Ec8678bCC6A483Adb6D7615c3939cA191348C",

    PositionManager_PLP_β: "0xcBf52AeC88446FFB2dbd4ADbB27d4A6468ef2f78",
    aPIKO: "0xB024210aDdBf311407795924b3C68Ba0A282b22C",
    PUSD: "0xBf3BCc95a72ec5aeAFA7c88fe776F4d6dC6fb068",
    LP_Farm: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    PIKOStaking: "0x0000000000000000000000000000000000000001",
    IDO: "0x0000000000000000000000000000000000000001",
    PIKO_burn: "0x0000000000000000000000000000000000000001",
    PIKO_LP: "0x0000000000000000000000000000000000000001",
    PIKO_DAO: "0x0000000000000000000000000000000000000001",
    dis_token: "0x0000000000000000000000000000000000000001",
    pikoLpToken: "0x0000000000000000000000000000000000000001",
  },
  324: {
    NATIVE_TOKEN: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    WETH: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    ETH: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    WBTC: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    BTC: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    USDC: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    USDT: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",

    // Oracle
    pyth: "0xf087c864AEccFb6A2Bf1Af6A0382B0d0f6c5D834",
    ServerPriceFeed: "0xfCe076F9fA38aa3a947808a66F0f877687A18884",
    VaultPriceFeed: "0xA42d35b46BF1621535a36dA1aFEA3bfB57Bc2b64",
    // Tokens
    esPIKO: "0xCaa5ba70830f8557fd6516Cf632b08AA4B6A528F",
    PIKO: "0xf8C6dA1bbdc31Ea5F968AcE76E931685cA7F9962",

    //  Common Utils
    randomSource: "0x4d804D35093FAE18116398207ca098a1A70414B0",
    NFTUtils: "0xe7746E06E61B65cCBc3CC0f48a2eD6Cc21C1bF2a",

    // Fee
    RewardRouter: "0xeF4B5d85C34F746d024293b364205000C86Bc4Ff",
    FeeRouter: "0x86989DE7cE37Eb0DFC7b0Aa5Ae64fb165C31b341",
    UserFeeResv: "0x5E1796B05b717f7D4Cb4BA180D430D2F334C1B54",

    //DAO
    Treasury: "0xCED97A84296CBcAD13A3539EAda64aaD5F57AB37",

    // PID
    PID: "0x4A14e417cB7D641f53a8D4F9B2Cf1A714251ee03",
    PIDLogic: "0x7D3Ea4Efe6459d6a030E65EEE9C1Ab0c636C9044",
    InfoHelper: "0x6092aff4Aa8F4BcD47AB67a3891dF1e48627D5BB",

    // Reader
    VaultReader: "0x903C5cBa713d8B33d247fAf7F74cD4fD10Fc8936",
    Reader: "0x83ECBdB3D837f0449A822A9A8649a87D80571136",
    PositionReader: "0xc35167183C56d88dDBC4864F73b7aE06205485c2",

    // plp

    PLP_β: "0x8FdF5A1880832e9043Ce35B729A1e6C850b09b23",
    Vault_PLP_β: "0x87A43dfAB5068c9Ae2f75da2906559bc9A71b42d",
    VaultUtils_PLP_β: "0x6d105053604FC72D6c78Ae84A2053a72ad8c87bf",
    VaultStorage_PLP_β: "0x33579126BF9B42113BF69783c1c307e04b15EA14",
    RouterSign_PLP_β: "0xdD83B267b395679989697490e4fce73DC032E5aa",
    PlpManager_PLP_β: "0xa5D4263E56C17fe33c565f40f183C3F222206744",
    RewardPool_PLP_β: "0x536D092230A3372030a63414f5932CAD74fC9Db6",
    OrderBook_PLP_β: "0x13444b0e5582Ee8dd082711507697A281760A6Ae",
    PositionRouter_PLP_β: "0xf68e3F71691335525e4275B1f0cc89AA3477a384",

    // Activity
    BurnToScore: "0x08601c93b3bf68040d0df1efdEb547E0f17A41F9",
    InviteToEarn: "0x0A625190D47da9F0746b40Cc08fEE10bF31cd752",
    TradeRebate: "0x6bb102dE0c228EC489FD18677e25c99920Bb02cB",

    // Contract
    vePIKO: "0x2A283C805D11ad77161Be0c503805a2b8Bc7Fd84",
    veesPIKO: "0x4203511B63F3D3ac6dEA18cA9d1f249f11EC9C4D",
    totalVeToken: "0xdbD9Bf16Dc549Dc0272ABFF1670Fb6E658EcCb20",
    vePIKO_stakeHelper: "0x1719a41afc0648dAB9CFc3f867Fd588ABAd3F3e3",
    veesPIKO_stakeHelper: "0xDf91D242d21053DE3caC79932F5E006D88a6Af3D",
    TokenVesting: "0xc1fd08878cD9c7615B72378830032a93478c4cF0",
    DisFee: "0xc543E68d0BBe9Bef53A35c78F7f6800B655DF6A2",
    DisToken: "0x7750488C6cB42Fb6074CdaC69F103596aC5F094c",

    // end

    aPIKO: "0xB024210aDdBf311407795924b3C68Ba0A282b22C",
    PUSD: "0xBf3BCc95a72ec5aeAFA7c88fe776F4d6dC6fb068",

    PnkLp: "0x566Ec8678bCC6A483Adb6D7615c3939cA191348C",
    TwapPrice: "0x9ca2d020BeAaE5dF204b9CdE5Ae37CC20D8175b0",
    TreasuryResv: "0xf521b5dba6FF0d513eC08Fe9eF6a46bE90Cc9a8e",
    InstVesting: "0x8b34cDEde186d59Bdfa3a67e005568D6f45e7261",
    Bond: "0x56a4Aef7579dE9Ed7328eC91eBeB8A7eb0AF75bF",

    LP_Farm: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    PIKOStaking: "0x0000000000000000000000000000000000000001",
    IDO: "0x0000000000000000000000000000000000000001",
    PIKO_burn: "0x0000000000000000000000000000000000000001",
    PIKO_LP: "0x0000000000000000000000000000000000000001",
    PIKO_DAO: "0x0000000000000000000000000000000000000001",
    dis_token: "0x0000000000000000000000000000000000000001",
    pikoLpToken: "0x0000000000000000000000000000000000000001",

    iPIKO: "0x12931767C77cB211742B942971b7A6c512654dC9",
    iPIKOVesting: "0x2BE011A5806Db4aB889BE527d06F5aEB0f797FbC",
  },
  5001: {
    NATIVE_TOKEN: "0x1236dc14efb42081d109037e60030c54da25b1f2",
    WETH: "0x1A03B5D533AB076ff17bE2049B5E9F4E5cde4FeB",
    ETH: "0x1A03B5D533AB076ff17bE2049B5E9F4E5cde4FeB",
    BTC: "0xcD95537E742DE0eCcaaae656929676982EEF007A",
    USDT: "0x896B643636b39d65052EB855E10eF860ac6ddD42",
    USDC: "0xD1a8Cc4F682EDE9A3E80ac8Fb657a73a51A8c005",
    DAI: "0x7c89cD989d56BC67414c10236947c8FF2156BEe6",
    DOGE: "0x4eEfCf59DE17939fD681233aD58a24c7E9e6cC4A",
    LINK: "0x823A2784Cc71E352c80E8DCb2AB0fa68DC0Fe5DA",
    MATIC: "0x7FC562070d9cd9811d397b2cC40d794A2BdDE411",
    ADA: "0x34929bb4E7A17996c0e9B4fd6719dBcc2B66783b",
    DOT: "0xE723e7c0dE4E3552F713eCAE99C2f674CEFf514b",
    PNK: "0x77Ae539A1f531Ed339E11FedBF5A940eC22eaeF8",
    ServerPriceFeed: "0xB8c304B98DeD67481f55565e8BFfCaa24D441D88",
    VaultPriceFeedV3Fast: "0x729E9b2cDCC9564956875316Cd41192B35aCd197",
    randomSource: "0x4877bc67839800db6aD53c1B4c5D84238916b6DD",
    NFTUtils: "0xa36e0E0FBcd423F1812491Ad9d9Db60152B1E1a5",
    Timelock: "0xB766bba988AeA08690A5DC8C3B930d73942145C3",
    PID: "0xAd25ac3deE93caBBDe8A925A3d866F567a9C1576",
    FeeRouter: "0xf4ED4C805be4425E50E1eE03E4E019B1707d4065",
    RewardRouter: "0x4d35CC973b6038EAaaf06C0D9Ab61790D891A4dD",
    UserFeeResv: "0x4F0CBe519a03e0d3BE3A77279F5F97986b47E6ec",
    LpManager: "0x8840C0B6D29f43D1e22bdd67d162a2Fc63B8023c",
    Reader: "0xB19577ad7617BbC26667b71e953aCf98f8d660B2",
    PositionReader: "0x31292d10Bb135180903f494eA4ba744A3F29720d",
    OrderBookReader: "0xaB341B44fd83FC140B74eaf2fa0fC89b5FDAeB80",
    VaultReader: "0xf7919Bf8A3F69B0bD1d12ee7697f56558C4798dd",
    PLP: "0x7a6Ed37C4690F930640F92379FD2Be7fD32f6e03",
    InfoHelper: "0x114e5CD7b2A81424c73cB99e49066F32DBDe3475",
    BurnToScore: "0xb1B16622c09f6C1A792AEbDdDa89E00e8DeF3F56",
    InviteToEarn: "0x45699a5dB7361551043E723096e6161BeE035641",

    Vault: "0x07da10649555608fA24a450F518e730E223FbAB9",
    VaultUtils: "0xA7712B6845051BB3be21e1995253664cd80dA7Ed",
    VaultStorage: "0x2eAb3B4dB41a9D970b59459490d0e6BB0Db20B6F",
    RouterSign: "0x302c60E802848fE1C8dfc89AbA3C3eeEBaCe3F9F",
    PlpManager: "0x603C9F8a7c807D6e8EEfd6875678971B59ad4491",
    Router: "0x7E923De39CC56Ee26030D7406e1B766F4c668Adf",
    PositionRouter: "0x3fC60D96a3735Bdd433DA41Aa978F55C30F29904",
    RewardTracker: "0x98b63F268A3D03BB4B9B1E75206A30d1e0416382",
    OrderBook: "0x44111682420F634dBA24778271914C3357299Ab8",
    PositionManager: "0x5aAAE46594D4f0518E03DEa1b6D4D291B4de38AF",

    PnkLp: "0x566Ec8678bCC6A483Adb6D7615c3939cA191348C",
    TwapPrice: "0x9ca2d020BeAaE5dF204b9CdE5Ae37CC20D8175b0",
    Treasury: "0xAB2C8973d64b935845Ba8d7EA206873e458a7544",
    TreasuryResv: "0xf521b5dba6FF0d513eC08Fe9eF6a46bE90Cc9a8e",
    InstVesting: "0x8b34cDEde186d59Bdfa3a67e005568D6f45e7261",
    Bond: "0x56a4Aef7579dE9Ed7328eC91eBeB8A7eb0AF75bF",

    // PLP_β
    PLP_β: "0x5Dce3337CE99450718e101057E9C1D6cb62ed5A6",
    Vault_PLP_β: "0xa63e5EA4Aa31f3Ac66b585b525a164416729BBbc",
    VaultUtils_PLP_β: "0x3BE618888f0951953E338f65b2519708F58D30a3",
    VaultStorage_PLP_β: "0x12Fc921B516863ac778336e6b0e869d51165AF82",
    RouterSign_PLP_β: "0xcf5A3c7fd630b42473B70176975650FfeD8A9Ed7",
    PlpManager_PLP_β: "0xbc4CA6b8D72634316EFaa884f264F8F257268E06",
    Router_PLP_β: "0xf96401c4F60Ab8C1FB5762cDe338cD615c75a6e4",
    RewardTracker_PLP_β: "0xa23883800087f05BE1aa602548FCd85a5295a3C3",
    OrderBook_PLP_β: "0x15BD62950E0a3bD7E43eE8FCb1f6fe9E2D09c4B1",
    PositionRouter_PLP_β: "0xC18F8366a701943B921bE44A3B15e65db86e29dB",
    PositionManager_PLP_β: "0xcBf52AeC88446FFB2dbd4ADbB27d4A6468ef2f78",

    PIKO: "0xa604E1cA54093692d9De106976c389A4f7Ad1093",
    vePIKO: "0xD05585aCFabc20abd9E223eA9F1EAafAc3F8c2dc",
    vePIKO_stakeHelper: "0x43a5dBEec20C0fc13eE6c55887194e7006A49B35",
    DisToken: "0xef407cFB80Bf06Be5D84EE641e8406D45e25c2dA",
    aPIKO: "0xB024210aDdBf311407795924b3C68Ba0A282b22C",
    esPIKO: "0xe7a0e9BEA09e4A437Ccb11ab44bE15d3133E2e3c",
    veesPIKO: "0x97cC847bF229787fEdC614C96169e1aC298C31fE",
    veesPIKO_stakeHelper: "0xB42000D50ae7D3262f8c25a45692E963273b6131",
    PUSD: "0xBf3BCc95a72ec5aeAFA7c88fe776F4d6dC6fb068",
    DisFee: "0x28374ba9A1cad98D946ef8de9638ed579dC35B50",
    totalVeToken: "0x7569B1E13851698E47bdc2920329e9d16B6224ef",
    TokenVesting: "0x93775996c6E8124BadE65c3Ce57C67A9CE77C7ca",

    TradeRebate: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    PIKOStaking: "0x0000000000000000000000000000000000000001",
    IDO: "0x0000000000000000000000000000000000000001",
    PIKO_burn: "0x0000000000000000000000000000000000000001",
    PIKO_LP: "0x0000000000000000000000000000000000000001",
    PIKO_DAO: "0x0000000000000000000000000000000000000001",
    dis_token: "0x0000000000000000000000000000000000000001",
    pikoLpToken: "0x0000000000000000000000000000000000000001",
    faucet: "0x125BA39A4744eFAC52A911D2B73c3cA02442F71A",
    VaultPriceFeed: "0x3198463F1FA4737d21d73EffC888f058788d43CA",
  },
};

export const PRICE_KEY = {
  [280]: {
    KEY_BNB:
      "0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a",
    KEY_WBNB:
      "0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a",
    KEY_BTC:
      "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b",
    KEY_ETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_WETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_BUSD:
      "0x06c532524fabd49cc142815502d785e4f34ec3bd035480efb770568d423f46c6",
    KEY_USDC:
      "0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722",
    KEY_USDT:
      "0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588",
    KEY_DAI:
      "0x87a67534df591d2dd5ec577ab3c75668a8e3d35e92e27bf29d9e2e52df8de412",

    KEY_DOGE:
      "0x31775e1d6897129e8a84eeba975778fb50015b88039e9bc140bbd839694ac0ae",
    KEY_ADA:
      "0x73dc009953c83c944690037ea477df627657f45c14f16ad3a61089c5a3f9f4f2",
    KEY_LINK:
      "0x83be4ed61dd8a3518d198098ce37240c494710a7b9d85e35d9fceac21df08994",
    KEY_CAKE:
      "0x3ea98c0336f6a8506dc34567da82178f6f7a664b206ae8eaf8ab8d96721ecccc",
    KEY_DOT:
      "0x36032e522b810babd8e3148e9f0d588af9e95e93b97ffb58566b837fdbd31f7f",
    KEY_FIL:
      "0xb5622d32f36dc820af288aab779133ef1205d3123bbe256603849b820de48b87",
    KEY_GMT:
      "0xc999fe8a75bf9528931deb832e2934dd1ac138757d7f2c1398675b6f521d556f",
    KEY_INJ:
      "0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3",
    KEY_LTC:
      "0x997e0bf451cb36b4aea096e6b5c254d700922211dd933d9d17c467f0d6f34321",
    KEY_MATIC:
      "0xd2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5",
    KEY_MBOX:
      "0x70bf2974182b6ebf4eac64977170074315e7a07d7b58a963758060924ee9a192",
    KEY_XVS:
      "0x6ff709bf1f0fdb46d991ae6839163df1e050d143429d737fc650b6b214347808",
  },
  [324]: {
    KEY_ARB:
      "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
    KEY_BNB:
      "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
    KEY_WBNB:
      "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
    KEY_BTC:
      "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    KEY_ETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_WETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_BUSD:
      "0x5bc91f13e412c07599167bae86f07543f076a638962b8d6017ec19dab4a82814",
    KEY_USDC:
      "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
    KEY_USDT:
      "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b",
    KEY_XRP:
      "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
    KEY_LTC:
      "0x6e3f3fa8253588df9326580180233eb791e03b443a3ba7a1d892e73874e19a54",
    KEY_DOGE:
      "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
    KEY_LINK:
      "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
    KEY_MATIC:
      "0x5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
    KEY_ADA:
      "0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d",
    KEY_DOT:
      "0xca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b",
    KEY_DAI:
      "0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd",
  },
  [5001]: {
    KEY_BTC:
      "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b",
    KEY_ETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_WETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_BUSD:
      "0x06c532524fabd49cc142815502d785e4f34ec3bd035480efb770568d423f46c6",
    KEY_USDC:
      "0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722",
    KEY_USDT:
      "0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588",
    KEY_DAI:
      "0x87a67534df591d2dd5ec577ab3c75668a8e3d35e92e27bf29d9e2e52df8de412",

    KEY_DOGE:
      "0x31775e1d6897129e8a84eeba975778fb50015b88039e9bc140bbd839694ac0ae",
    KEY_ADA:
      "0x73dc009953c83c944690037ea477df627657f45c14f16ad3a61089c5a3f9f4f2",
    KEY_LINK:
      "0x83be4ed61dd8a3518d198098ce37240c494710a7b9d85e35d9fceac21df08994",
    KEY_CAKE:
      "0x3ea98c0336f6a8506dc34567da82178f6f7a664b206ae8eaf8ab8d96721ecccc",
    KEY_DOT:
      "0x36032e522b810babd8e3148e9f0d588af9e95e93b97ffb58566b837fdbd31f7f",
    KEY_FIL:
      "0xb5622d32f36dc820af288aab779133ef1205d3123bbe256603849b820de48b87",
    KEY_GMT:
      "0xc999fe8a75bf9528931deb832e2934dd1ac138757d7f2c1398675b6f521d556f",
    KEY_INJ:
      "0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3",
    KEY_LTC:
      "0x997e0bf451cb36b4aea096e6b5c254d700922211dd933d9d17c467f0d6f34321",
    KEY_MATIC:
      "0xd2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5",
    KEY_MBOX:
      "0x70bf2974182b6ebf4eac64977170074315e7a07d7b58a963758060924ee9a192",
    KEY_XVS:
      "0x6ff709bf1f0fdb46d991ae6839163df1e050d143429d737fc650b6b214347808",
  },
};
