/* eslint-disable global-require */
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { Web3ContextProvider } from "./hooks/web3Context";
import App from "./App";
import store from "./store";
import { MulticallUpdater } from "src/lib/state/multicall";
import HttpUpdater from "src/state/http/updater";
import { BlockNumberProvider } from "src/lib/hooks/useBlockNumber";
import ThemeProvider, { ThemedGlobalStyle } from "./theme";
import { LanguageProvider } from "./i18n";
const Updaters = () => {
  return (
    <>
      <MulticallUpdater />
      <HttpUpdater />
    </>
  );
};
export default function Root() {
  return (
    <Provider store={store}>
      <HashRouter>
        <LanguageProvider>
          <Web3ContextProvider>
            <BlockNumberProvider>
              <Updaters />
              <ThemeProvider>
                <ThemedGlobalStyle />
                <App />
              </ThemeProvider>
            </BlockNumberProvider>
          </Web3ContextProvider>
        </LanguageProvider>
      </HashRouter>
    </Provider>
  );
}
