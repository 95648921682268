import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ReactComponent as PIKOcon } from "src/assets/images/PIKO.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
const MyBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
`;
const PikoPrice = () => {
  const PIKOPrice = useGetPikoPrice();

  return (
    <MyBox className="font-weight-6 color4">
      <PIKOcon width={30} height={30} />$
      {formatAmount2(PIKOPrice || 0, 2, true)}
    </MyBox>
  );
};

export default PikoPrice;
