import { ApolloClient, InMemoryCache } from "@apollo/client";

const chainID = process.env.REACT_APP_NETWORKID

const pikoGraphClient_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats");
const pikoGraphClient_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats");
const pikoGraphClient_zk_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/pnk");
const pikoGraphClient_zk_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats");

const pikoGraphClient_daily_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")
const pikoGraphClient_daily_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")
const pikoGraphClient_daily_zk_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")
const pikoGraphClient_daily_zk_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")

const pidGraphClient_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")
const pidGraphClient_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")
const pidGraphClient_zk_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/pid")
const pidGraphClient_zk_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats")

const vestakeTimeUrl = ""
const vestakeTime_dev = vestakeTimeUrl + "?chain=bsc"
const vestakeTime_prod = vestakeTimeUrl + "?chain=bsc"
const vestakeTime_zk_dev = vestakeTimeUrl + "?chain=zk"
const vestakeTime_zk_prod = vestakeTimeUrl + "?chain=zk"

export const chainlinkClient = createClient("https://api.thegraph.com/subgraphs/name/deividask/chainlink");

function createClient(uri) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}

export const getVestakeTimeUrl = (chainID) => {
  switch (chainID) {
    case 56:
      return vestakeTime_prod
      break;
    case 97:
      return vestakeTime_dev
      break;
    case 324:
      return vestakeTime_zk_prod
      break;
    case 280:
      return vestakeTime_zk_dev
      break;
    default:
      return vestakeTime_zk_prod
      break;
  }
}

export const getPikoGraphClient_all = (roundid) => {
  return `?roundid=${roundid}`
}

export const getPikoGraphClient = (chainID) => {
  switch (chainID) {
    case 56:
      return pikoGraphClient_prod
      break;
    case 97:
      return pikoGraphClient_dev
      break;
    case 280:
      return pikoGraphClient_zk_dev
      break;
    case 324:
      return pikoGraphClient_zk_prod
      break;
    case 5001:
      return
    default:
      return pikoGraphClient_zk_dev
      break;
  }
}

export const getPikoGraphClient_daily = (chainID) => {
  switch (chainID) {
    case 56:
      return pikoGraphClient_daily_prod
      break;
    case 97:
      return pikoGraphClient_daily_dev
      break;
    case 280:
      return pikoGraphClient_daily_zk_dev
      break;
    case 324:
      return pikoGraphClient_daily_zk_prod
      break;
    case 5001:
      return
    default:
      return pikoGraphClient_daily_zk_dev
      break;
  }
}

export const getPidGraphClient = (chainID) => {
  switch (chainID) {
    case 56:
      return pidGraphClient_prod
      break;
    case 97:
      return pidGraphClient_dev
      break;
    case 280:
      return pidGraphClient_zk_dev
      break;
    case 324:
      return pidGraphClient_zk_prod
      break;
    case 5001:
      return
    default:
      return pidGraphClient_zk_dev
      break;
  }
}
