import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { isAddress } from "src/utils";
import { useWeb3Context } from "./web3Context";
import { addresses as ADDRESSMAP } from "../configs/constants";
import { abi as PositionReaderABI } from "../abis/PositionReader.json";
import { abi as VaultReaderABI } from "../abis/VaultReader.json";
import { abi as VaultPriceFeedV21FastABi } from "../abis/VaultPriceFeedV21Fast.json";
import { abi as VaultUtilsABI } from "../abis/VaultUtils.json";
import { abi as PlpTransferHelperABI } from "../abis/PlpTransferHelper.json";
import { abi as TokenABI } from "../abis/Token.json";
import { abi as PairABI } from "../abis/Pair.json";
import { abi as RouterABI } from "../abis/Router.json";

import { abi as RouterSignAbi } from "../abis/RouterSign.json";
import { abi as OrderBookABI } from "../abis/OrderBook.json";
import { abi as OrderBookReaderABI } from "../abis/OrderBookReader.json";
import { UniswapInterfaceMulticall } from "@uniswap/redux-multicall/dist/abi/types";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";
import { abi as StakingPoolABI } from "src/abis/StakingPool.json";
import {
  AddressMap,
  AddressZero,
  MULTICALL_ADDRESS,
} from "../constants/address";
import {
  PositionReader,
  VaultV2,
  VaultReader,
  VaultUtils,
  VaultPriceFeedV21Fast,
  Token,
  RouterSign,
  Pair,
  PlpTransferHelper,
  PIKO,
  PIKOStake,
  PLP,
  RewardTracker,
  RewardRouter,
  InfoHelper,
  PID,
  Reader,
  OrderBook,
  OrderBookReader,
  Router,
  StakingPool,
  TokenVesting,
  Treasury,
  PIKOStaking,
  RedeemPIKOT,
  DisToken,
  PUSD,
  VaultPriceFeed,
  Bond,
  InstVesting,
  TwapPrice,
  InviteToEarn,
  TradeRebate,
} from "src/abis/types";
import { abi as TokenVestingABI } from "../abis/TokenVesting.json";
import { abi as TreasuryABI } from "../abis/Treasury.json";
import { abi as PIKOStakingABI } from "../abis/PIKOStaking.json";
import { abi as RedeemPIKOTABI } from "../abis/RedeemPIKOT.json";
import { abi as PUSDABI } from "src/abis/PUSD.json";
import { abi as PIKOABI } from "src/abis/PIKO.json";
import { abi as PIKOStakeABI } from "../abis/PIKOStake.json";
import { abi as ReaderABI } from "../abis/ReaderV2.json";
import { abi as VaultV2ABI } from "../abis/VaultV2.json";
import { abi as PlpABI } from "../abis/PLP.json";
import { abi as RewardTrackerABI } from "../abis/RewardTracker.json";
import { abi as DisTokenABI } from "../abis/DisToken.json";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { ethers } from "ethers";
import { addresses as ADDRESS } from "../configs/constants";
import { abi as InfoHelperABI } from "../abis/InfoHelper.json";
import { abi as PIDABI } from "../abis/PID.json";
import { abi as VaultPriceFeedABI } from "../abis/VaultPriceFeed.json";
import { abi as BondABI } from "../abis/Bond.json";
import { abi as InstVestingABI } from "src/abis/InstVesting.json";
import { abi as TwapPriceABI } from "src/abis/TwapPrice.json";
import { abi as InviteToEarnAbi } from "src/abis/InviteToEarn.json";
import { abi as TradeRebateAbi } from "src/abis/TradeRebate.json";

const { abi: MulticallABI } = UniswapInterfaceMulticallJson;
export function useContract<T extends Contract = Contract>(
  _address: AddressMap | string | undefined,
  ABI: any[]
): T | undefined {
  const { chainID: chainId, provider, address: account } = useWeb3Context();
  const signer = provider?.getSigner();
  const address = useMemo(() => {
    if (!chainId) return;
    return typeof _address == "string"
      ? _address
      : _address
      ? _address[chainId]
      : undefined;
  }, [_address, chainId]);

  return useMemo(() => {
    if (!provider || !address) return;

    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`);
    }

    let contract;
    if (!account) {
      // If you are not logged in, you need to display data. Add an account to simulate real data
      const wallet = new ethers.Wallet(
        "c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5",
        provider
      );

      contract = new Contract(address, ABI, wallet.provider);
    } else {
      contract = new Contract(address, ABI, signer ? signer : undefined);
    }
    return contract;
  }, [provider, address, ABI, account, signer]) as T;
}

export const usePositionReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<PositionReader>(
    ADDRESSMAP[chainID]?.PositionReader,
    PositionReaderABI
  );
};

export const useOrderBookContract = (orderBookAddress: string) => {
  return useContract<OrderBook>(orderBookAddress, OrderBookABI);
};

export const useOrderBookReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<OrderBookReader>(
    ADDRESSMAP[chainID]?.OrderBookReader,
    OrderBookReaderABI
  );
};

export const useVaultV2Contract = (vaultAddress: string) => {
  return useContract<VaultV2>(vaultAddress, VaultV2ABI);
};

export const useVaultReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<VaultReader>(
    ADDRESSMAP[chainID]?.VaultReader,
    VaultReaderABI
  );
};

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESS,
    MulticallABI
  ) as UniswapInterfaceMulticall;
}

export function useVaultUtilsContract(vaultUtilsAddress: string) {
  return useContract<VaultUtils>(vaultUtilsAddress, VaultUtilsABI);
}

export function usePlpTransferHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<PlpTransferHelper>(
    ADDRESSMAP[chainID]?.PlpTransferHelper,
    PlpTransferHelperABI
  );
}

export function useVaultPriceFeedV2Fast() {
  const { chainID } = useWeb3Context();
  return useContract<VaultPriceFeedV21Fast>(
    ADDRESSMAP[chainID]?.VaultPriceFeedV2Fast,
    VaultPriceFeedV21FastABi
  );
}

export function useTokenContract(address?: string) {
  return useContract<Token>(address, TokenABI);
}

export function useRouterSignContract(address?: string) {
  return useContract<RouterSign>(address, RouterSignAbi);
}

export function usePairContract(address?: string) {
  return useContract<Pair>(address, PairABI);
}

export function usePUSDContract() {
  const { chainID } = useWeb3Context();
  return useContract<PUSD>(ADDRESS[chainID]?.PUSD, PUSDABI);
}

export function usePIKOStakingContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKOStaking>(
    ADDRESS[chainID]?.PIKOStaking,
    PIKOStakingABI
  );
}

export function useRedeemPIKOTContract() {
  const { chainID } = useWeb3Context();
  return useContract<RedeemPIKOT>(
    ADDRESS[chainID]?.RedeemPIKOT,
    RedeemPIKOTABI
  );
}

export function useTreasuryContract() {
  const { chainID } = useWeb3Context();
  return useContract<Treasury>(ADDRESS[chainID]?.Treasury, TreasuryABI);
}

export function usePIKOContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKO>(ADDRESS[chainID]?.PIKO, PIKOABI);
}

export function useEsPIKOContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKO>(ADDRESS[chainID]?.esPIKO, PIKOABI);
}

export function useIPIKOContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKO>(ADDRESS[chainID]?.iPIKO, PIKOABI);
}

export function usePIKOTContract() {
  const { chainID } = useWeb3Context();
  return useTokenContract(ADDRESS[chainID]?.PIKOT);
}

export function useTotalVeTokenContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKOStake>(ADDRESS[chainID]?.totalVeToken, PIKOStakeABI);
}

export function useVePIKOContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKOStake>(ADDRESS[chainID]?.vePIKO, PIKOStakeABI);
}

export function useVeesPIKOContract() {
  const { chainID } = useWeb3Context();
  return useContract<PIKOStake>(ADDRESS[chainID]?.veesPIKO, PIKOStakeABI);
}

export function usePLPContract(address?: string) {
  return useContract<PLP>(address, PlpABI);
}

export function useTokenVestingContract() {
  const { chainID } = useWeb3Context();
  return useContract<TokenVesting>(
    ADDRESS[chainID]?.TokenVesting,
    TokenVestingABI
  );
}

export function useVestingContract_iPIKO() {
  const { chainID } = useWeb3Context();
  return useContract<TokenVesting>(
    ADDRESS[chainID]?.iPIKOVesting,
    TokenVestingABI
  );
}

export function useRewardTrackerContract(address?: string) {
  return useContract<RewardTracker>(address, RewardTrackerABI);
}

export function useDisTokenContract() {
  const { chainID } = useWeb3Context();
  return useContract<DisToken>(ADDRESS[chainID]?.DisToken, DisTokenABI);
}

export function useDisFeeContract() {
  const { chainID } = useWeb3Context();
  return useContract<DisToken>(ADDRESS[chainID]?.DisFee, DisTokenABI);
}

export function useRewardRouterContract() {
  const { chainID } = useWeb3Context();
  return useContract<RewardRouter>(
    ADDRESS[chainID]?.RewardRouter,
    RewardRouterABI
  );
}

export function usePIDContract() {
  const { chainID } = useWeb3Context();
  return useContract<PID>(ADDRESS[chainID]?.PID, PIDABI);
}

export function useInfoHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<InfoHelper>(ADDRESS[chainID]?.InfoHelper, InfoHelperABI);
}

export function useReaderContract() {
  const { chainID } = useWeb3Context();
  return useContract<Reader>(ADDRESS[chainID]?.Reader, ReaderABI);
}

export function useRouterContract(address?: string) {
  return useContract<Router>(address, RouterABI);
}

export function useStakePoolContract(address?: string) {
  return useContract<StakingPool>(address, StakingPoolABI);
}

export function usePikoDAOContract() {
  const { chainID } = useWeb3Context();
  return useContract<Token>(ADDRESS[chainID]?.PIKO_DAO, TokenABI);
}

export function useVaultPriceFeedContract() {
  const { chainID } = useWeb3Context();
  return useContract<VaultPriceFeed>(
    ADDRESS[chainID]?.VaultPriceFeed,
    VaultPriceFeedABI
  );
}

export function useBondContract() {
  const { chainID } = useWeb3Context();
  return useContract<Bond>(ADDRESS[chainID]?.Bond, BondABI);
}

export function useInsetVestingContract() {
  const { chainID } = useWeb3Context();
  return useContract<InstVesting>(
    ADDRESS[chainID]?.InstVesting,
    InstVestingABI
  );
}

export function useTwapPriceContract() {
  const { chainID } = useWeb3Context();
  return useContract<TwapPrice>(ADDRESS[chainID]?.TwapPrice, TwapPriceABI);
}

export function useInviteToEarnContract() {
  const { chainID } = useWeb3Context();
  return useContract<InviteToEarn>(
    ADDRESS[chainID]?.InviteToEarn,
    InviteToEarnAbi
  );
}

export function useTradeRebateContract() {
  const { chainID } = useWeb3Context();
  return useContract<TradeRebate>(
    ADDRESS[chainID]?.TradeRebate,
    TradeRebateAbi
  );
}
