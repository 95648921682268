import { ethers } from "ethers";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getContractAddress } from "src/Addresses";
import { useAppSelector, useWeb3Context } from "src/hooks";
import { useVaultReaderContract } from "src/hooks/useContract";
import { useGetPLPData } from "src/hooks/useGetPLPData";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { BN } from "src/utils/bn";
const { AddressZero } = ethers.constants;
export function useSwapFeeBas(
  plpName?: string,
  amount?: any,
  tokenOut?: string
) {
  const VaultReader = useVaultReaderContract();
  const { chainID } = useWeb3Context();

  const plpDataInfo = useGetPLPData(plpName);
  const nativeTokenAddress = getContractAddress(chainID, "NATIVE_TOKEN");

  const tokensArr = useSelector((state: any) => {
    return state.app.tokensArr || [];
  });
  const tokenMaps = useAppSelector((state: any) => {
    return state.app.tokensMap || {};
  });
  const getTokenList = tokensArr?.filter((item) => item.name === plpName)[0]
    ?.chainInfoArr;
  const tokenAddr = useMemo(
    () => getTokenList?.map((res) => res.address),
    [getTokenList]
  );
  const params = useMemo(() => {
    if (!plpDataInfo || !tokenAddr) return [undefined];

    let out;
    if (tokenOut) {
      out =
        tokenOut === AddressZero
          ? nativeTokenAddress.toLocaleLowerCase()
          : tokenOut.toLocaleLowerCase();
    } else {
      out = nativeTokenAddress.toLocaleLowerCase();
    }

    const swapTokenInfo = tokenMaps[out];
    if (!swapTokenInfo?.price) return [undefined];
    const smt = amount == "" ? 1 : amount;
    const swapAmount = BN(smt)
      .times(swapTokenInfo.price)
      .times(BN(10).pow(30))
      .toFixed(0);
    return [
      plpDataInfo.vault_address,
      tokenAddr,
      nativeTokenAddress,
      swapAmount,
    ];
  }, [plpDataInfo, tokenAddr, tokenOut, tokenMaps, amount, nativeTokenAddress]);

  const { result } = useSingleCallResult(VaultReader, "plpFeeInfo", params, {
    gasRequired: 4000000,
  });
  const tokenMap = useMemo(() => {
    if (!result) return {};
    const data = result[0];
    return tokenAddr?.reduce((pre: any, curr, index) => {
      pre[curr.toLocaleLowerCase()] = {
        buyFee: BN(data[index * 3].toString()).toFixed(),
        sellFee: BN(data[index * 3 + 1].toString()).toFixed(),
        swapFee: BN(data[index * 3 + 2].toString()).toFixed(),
      };
      return pre;
    }, {});
  }, [result, tokenAddr]);
  return tokenMap;
}
