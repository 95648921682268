import { Box, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { ReactComponent as Dropdown } from "src/assets/images/dropdown.svg";
import { ReactComponent as WrongIcon } from "src/assets/images/wrong.svg";
import { ThemedText } from "src/theme/text";
import React, { useCallback } from "react";
import { ChainBtn } from "./ChainBtn";
import { styled } from "@mui/material/styles";
import { CHAINNAME_LIST, NETWORK_LIST } from "src/constants/chains";
import { useWeb3Context } from "src/hooks";

export const MenuBox = styled(Menu)`
  .MuiPaper-root {
    padding: 6px 0;
    background: #161616;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 4px;
  }
  .MuiMenu-paper {
    min-width: 140px;
  }
  .MuiMenuItem-root {
    height: 40px;
    min-height: 40px;
    padding: 0 12px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

export default function ChainBox() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { chainID, Error, switchNetwork2 } = useWeb3Context();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlerSwitch = useCallback(
    async (data: any) => {
      await switchNetwork2(
        data.chainId,
        data.chainName,
        data.nativeCurrency,
        data.rpcUrl,
        data.blockExplorer
      );
      setAnchorEl(null);
    },
    [switchNetwork2]
  );
  return (
    <>
      <ChainBtn
        id="basic-button"
        className="hoverbtns"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {Error ? (
          <Box display="flex" alignItems="center">
            <WrongIcon />
            <ThemedText.Text1Medium fontSize={14} ml="8px">
              {isSmallScreen ? "Wrong" : "Wrong network"}
            </ThemedText.Text1Medium>
          </Box>
        ) : (
          <>
            <img
              width={30}
              height={30}
              src={CHAINNAME_LIST[chainID]?.icon}
              alt=""
            />
            {!isVerySmallScreen && (
              <ThemedText.Text1Medium fontSize={14} ml="10px">
                {CHAINNAME_LIST[chainID]?.chainName}
              </ThemedText.Text1Medium>
            )}
          </>
        )}
        <Dropdown className="downIcon" />
      </ChainBtn>
      <MenuBox
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {NETWORK_LIST.map((item, index) => {
          return (
            <MenuItem onClick={() => handlerSwitch(item)} key={index + "chain"}>
              <img width={20} height={20} src={item.icon} alt="" />
              <ThemedText.Text1Medium fontSize={14} ml="10px">
                {item.chainName}
              </ThemedText.Text1Medium>
            </MenuItem>
          );
        })}
      </MenuBox>
    </>
  );
}
