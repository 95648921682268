import { Box, Divider } from "@mui/material";
import styled from "styled-components/macro";
import TokenList from "./TokenList";
import { ReactComponent as Avatar } from "src/assets/images/avatar.svg";
import { ReactComponent as Copy } from "src/assets/images/copy.svg";
import { ReactComponent as DisconnectIcon } from "src/assets/images/disconnect.svg";
import { ThemedText } from "src/theme/text";
import { DisconnectBtn } from "../../Button/DisconnectBtn";
import { Trans } from "@lingui/macro";
import NoPid from "src/views/PID/Portfolio/AccountInfo/NoPid";
import { useBasicInfo } from "src/hooks/useQueryPID";
import { formatAmount2 } from "src/helpers/Helpers";
import { shorten, shorten2 } from "src/helpers";
import copy from "copy-to-clipboard";
import { useWeb3Context } from "src/hooks";
import { toastSuccess } from "src/helpers/toastHelpers";
import { useHistory } from "react-router-dom";

const DividerBox = styled(Divider)`
  border-color: ${({ theme }) => theme.bg3};
`;
const AccountBox = styled(Box)`
  max-width: 90vw;
  .account {
    padding: 18px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
  .info {
    padding: 10px;
    .pidBox {
      background: #224832;
      border-radius: 4px;
      position: relative;
      .text {
        position: absolute;
        font-family: "Gilroy-Bold";
        font-size: 140px;
        line-height: 169px;
        letter-spacing: -0.2px;
        color: #ffffff;
        opacity: 0.03;
        transform: rotate(-30deg);
      }
      .pid {
        height: 60px;
      }
      .points {
        height: 30px;
      }
      .points,
      .pid {
        padding: 0 10px;
        border-bottom: 1px solid ${({ theme }) => theme.bg3};
      }
    }
    .referral {
      .referralItem {
        padding: 10px;
        flex: 1;
      }
    }
  }
`;
type dataPros = {
  handleClose?: any;
};
export default function Account({ handleClose }: dataPros) {
  const baseInfo = useBasicInfo();
  const { chainID, disconnect, address } = useWeb3Context();
  const history = useHistory();
  const linkToPid = (e) => {
    setTimeout(() => {
      handleClose();
    }, 0);

    history.push({ pathname: "/PID" });
  };
  return (
    <AccountBox className="accountBox">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="account"
      >
        <Box display="flex" alignItems="center">
          <Avatar />
          <ThemedText.Text9Bold ml="12px" fontSize={18}>
            {shorten(address)}
          </ThemedText.Text9Bold>
        </Box>
        <DisconnectBtn onClick={disconnect}>
          <DisconnectIcon />
          <Box ml="8px">Disconnect</Box>
        </DisconnectBtn>
      </Box>
      <div className="info pointer" onClick={linkToPid}>
        {baseInfo?.rank > 0 ? (
          <div className="pidBox">
            <div className="text">PID</div>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="pid"
            >
              <ThemedText.Text4Bold>PID</ThemedText.Text4Bold>
              <ThemedText.Text9Bold>{baseInfo?.nickName}</ThemedText.Text9Bold>
              {/* <ThemedText.Text9Bold>
                {baseInfo?.nickName}#
                {baseInfo?.refCode ? baseInfo?.refCode : "--"}
              </ThemedText.Text9Bold> */}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="points"
            >
              <ThemedText.Text4Bold>Points</ThemedText.Text4Bold>
              <ThemedText.Text9 fontSize={14}>
                {formatAmount2(baseInfo?.score, 0, true)}
              </ThemedText.Text9>
            </Box>
            <Box display="flex" className="referral">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="referralItem"
              >
                <div className="">
                  <ThemedText.Text4Bold>
                    <Trans>Referral Code</Trans>
                  </ThemedText.Text4Bold>
                  <ThemedText.Text9 fontSize={14} mt="8px">
                    {baseInfo?.refCode ? baseInfo?.refCode : "--"}
                  </ThemedText.Text9>
                </div>
                <div
                  className="pointer"
                  onClick={() => {
                    copy(baseInfo?.refCode), toastSuccess("copy success");
                  }}
                >
                  <Copy />
                </div>
              </Box>
              <DividerBox orientation="vertical" flexItem />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="referralItem"
              >
                <div className="">
                  <ThemedText.Text4Bold>
                    <Trans>Referral Link</Trans>
                  </ThemedText.Text4Bold>
                  <ThemedText.Text9 fontSize={14} mt="8px">
                    {shorten2(
                      `https://app.pinnako.io/#/PID?ref=${baseInfo?.refCode}`,
                      chainID
                    )}
                  </ThemedText.Text9>
                </div>
                <div
                  className="pointer"
                  onClick={() => {
                    copy(
                      `https://app.pinnako.io/#/PID?ref=${baseInfo?.refCode}`
                    ),
                      toastSuccess("copy success");
                  }}
                >
                  <Copy />
                </div>
              </Box>
            </Box>
          </div>
        ) : (
          <NoPid handleCloses={handleClose} />
        )}
        <TokenList />
      </div>
    </AccountBox>
  );
}
