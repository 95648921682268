import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  [SupportedChainId.ZKTEST]: SupportedChainId.ZKTEST,
  [SupportedChainId.MANTTEST]: SupportedChainId.MANTTEST,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.ZKTEST,
  SupportedChainId.MANTTEST,
];
