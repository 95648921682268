import { useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import { addresses } from "src/configs/constants";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from ".";
import { ethers } from "ethers";
import { abi as FaucetABI } from "../abis/Faucet.json";
import { toastSuccess } from "src/helpers/toastHelpers";
import { handlerError } from "src/helpers/errorHelpers";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";

export default function useClaim() {
  const dispatch = useDispatch();
  const { chainID, address, provider } = useWeb3Context();
  const signer = provider.getSigner();

  const updateBlockNumber = useUpDataBlockNumber();

  const claimTokens = useCallback(async () => {
    if (chainID && provider && address) {
      let tx: any;
      try {
        const faucetContract = new ethers.Contract(
          addresses[chainID].faucet,
          FaucetABI,
          signer
        );
        tx = await faucetContract.claim();
        dispatch(
          fetchPendingTxns({ txnHash: tx.hash, text: "claim", type: "claim" })
        );
        const data = await tx.wait();
        updateBlockNumber(data);
        return tx;
      } catch (e: any) {
        handlerError(e);
      } finally {
        if (tx) {
          batch(() => {
            dispatch(clearPendingTxn(tx.hash));
            toastSuccess("Claim Success!", tx.hash);
          });
        }
      }
    }
  }, [chainID, provider, address, signer, dispatch, updateBlockNumber]);

  return { claimTokens };
}
