import moment from "moment";
import { useMemo } from "react";

import { addresses as ADDRESS } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { BN, toFromBN } from "src/utils/bn";
import { useGetPikoPrice } from "./get_price_piko";
import {
  usePIKOContract,
  useDisTokenContract,
  usePUSDContract,
  useDisFeeContract,
  useInfoHelperContract,
  useRewardRouterContract,
  useVePIKOContract,
  useVeesPIKOContract,
  useEsPIKOContract,
  useTotalVeTokenContract,
  useIPIKOContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { ethers } from "ethers";
import { getTokens } from "src/configs/Tokens";
import { compareAddress } from "src/utils/address";

export const usePUSDAccount = () => {
  const { address: account } = useWeb3Context();
  const PUSDContract = usePUSDContract();

  const { result } = useSingleCallResult(PUSDContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const usePUSDTotalSupply = () => {
  const PUSDContract = usePUSDContract();

  const { result } = useSingleCallResult(PUSDContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalSupply_ve = () => {
  const totalVeTokenContract = useTotalVeTokenContract();
  const { result } = useSingleCallResult(
    totalVeTokenContract,
    "totalSupplyAtNow"
  );

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalSupply_vePIKO = () => {
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalSupply_veesPIKO = () => {
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_PIKO = () => {
  const { address } = useWeb3Context();
  const PIKOContract = usePIKOContract();
  const { result } = useSingleCallResult(PIKOContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useVePIKOLocked = () => {
  const { address: account } = useWeb3Context();
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useVeesPIKOLocked = () => {
  const { address: account } = useWeb3Context();
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useVePIKOBalance = () => {
  const { address: account } = useWeb3Context();
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useVeesPIKOBalance = () => {
  const { address: account } = useWeb3Context();
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalStaked_PIKO = () => {
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "totalTokenSupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalStaked_esPIKO = () => {
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "totalTokenSupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const usePIKOStakedAccount = () => {
  const { address: account } = useWeb3Context();
  const PIKOContract = usePIKOContract();
  const { result } = useSingleCallResult(PIKOContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_esPIKO = () => {
  const { address: account } = useWeb3Context();
  const esPIKOContract = useEsPIKOContract();
  const { result } = useSingleCallResult(esPIKOContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_iPIKO = () => {
  const { address: account } = useWeb3Context();
  const iPIKOContract = useIPIKOContract();
  const { result } = useSingleCallResult(iPIKOContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useDistributorToken = () => {
  const DisTokenContract = useDisTokenContract();
  const { result } = useSingleCallResult(
    DisTokenContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useDistributorFee = () => {
  const DisTokenContract = useDisFeeContract();
  const { result } = useSingleCallResult(
    DisTokenContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
export const usePIKOTotalBalance = () => {
  const vePIKOBalance = useVePIKOBalance();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = usePIKOStakedAccount();
  const PUSDBalance = usePUSDAccount();
  const balance_esPIKO = useBalance_esPIKO();

  return useMemo(() => {
    if (
      !vePIKOBalance ||
      !PIKOBalance ||
      !PIKOStaked ||
      !PUSDBalance ||
      !balance_esPIKO
    )
      return {
        vePIKOBalance,
        PIKOBalance,
        PIKOStaked,
        PUSDBalance,
        balance_esPIKO,
      };
  }, [balance_esPIKO, PIKOBalance, PIKOStaked, PUSDBalance, vePIKOBalance]);
};
export const useQueryEarnApr = () => {
  const vePIKOBalance = useVePIKOBalance();
  const veesPIKOBalance = useVeesPIKOBalance();
  const weekRewards_PIKO = useDistributorToken();
  const weekrewards_usdc = useDistributorFee();
  const totalSupply_ve = useTotalSupply_ve();

  const PIKOPrice = useGetPikoPrice();
  const PIKOStakedAmount = useVePIKOLocked();
  const esPIKOStakedAmount = useVeesPIKOLocked();

  return useMemo(() => {
    if (
      !vePIKOBalance ||
      !veesPIKOBalance ||
      !weekRewards_PIKO ||
      !weekrewards_usdc ||
      !totalSupply_ve ||
      !PIKOPrice ||
      !PIKOStakedAmount ||
      !esPIKOStakedAmount
    )
      return;
    let PIKOApr1 = BN(0);
    let PIKOApr2 = BN(0);
    let USDCApr1 = BN(0);
    let USDCApr2 = BN(0);

    if (totalSupply_ve.gt(0)) {
      PIKOApr1 = weekRewards_PIKO.div(7).times(365).div(totalSupply_ve);
      PIKOApr2 = weekRewards_PIKO
        .div(7)
        .times(365)
        .div(totalSupply_ve)
        .times(4);
    }
    if (weekrewards_usdc.gt(0) && totalSupply_ve.gt(0)) {
      USDCApr1 = weekrewards_usdc
        .div(PIKOPrice)
        .div(7)
        .times(365)
        .div(totalSupply_ve);
      USDCApr2 = weekrewards_usdc
        .div(PIKOPrice)
        .div(7)
        .times(365)
        .div(totalSupply_ve)
        .times(4);
    }
    const minApr = PIKOApr1.plus(USDCApr1);
    const maxApr = PIKOApr2.plus(USDCApr2);
    const PIKOApr = vePIKOBalance.div(PIKOStakedAmount.amount).times(PIKOApr1);
    const USDCApr = vePIKOBalance.div(PIKOStakedAmount.amount).times(USDCApr1);
    const estApr = PIKOApr.plus(USDCApr);
    const currtimestamp = moment().valueOf();
    const currtimestampS = (currtimestamp / 1000).toFixed(0);
    const endTime = PIKOStakedAmount.endTime - Number(currtimestampS);
    const endTime2 = esPIKOStakedAmount.endTime - Number(currtimestampS);

    return {
      vePIKOBalance: vePIKOBalance,
      veesPIKOBalance: veesPIKOBalance,
      PIKOApr1,
      PIKOApr2,
      USDCApr1,
      USDCApr2,
      minApr,
      maxApr,
      PIKOApr,
      USDCApr,
      estApr,
      endTime,
      endTime2,
      PIKOStaked: PIKOStakedAmount.amount,
      esPIKOStaked: esPIKOStakedAmount.amount,
    };
  }, [
    vePIKOBalance,
    veesPIKOBalance,
    weekRewards_PIKO,
    weekrewards_usdc,
    totalSupply_ve,
    PIKOPrice,
    PIKOStakedAmount,
    esPIKOStakedAmount,
  ]);
};

export const usePUSDPoolInfo = () => {
  const RewardRouterContract = useRewardRouterContract();
  const { result } = useSingleCallResult(
    RewardRouterContract,
    "getPUSDPoolInfo"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return {
      totalCollateral: toFromBN(data[0], 30),
      circulatingSupply: toFromBN(data[2]),
      buyFees: BN(data[4].toString()),
      sellFees: BN(data[5].toString()),
    };
  }, [result]);
};

export const usePIKOStakeRewards = () => {
  const { address: account } = useWeb3Context();
  const DisTokenContract = useDisTokenContract();
  const DisFeeContract = useDisFeeContract();

  const { result: PIKOReward } = useSingleCallResult(
    DisTokenContract,
    "earned",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  const { result: PUSDReward } = useSingleCallResult(DisFeeContract, "earned", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!PIKOReward || !PUSDReward) return;
    const rewards_esPIKO = toFromBN(PIKOReward[0]);
    const rewards_usdc = toFromBN(PUSDReward[0]);
    return {
      rewards_esPIKO,
      rewards_usdc,
    };
  }, [PIKOReward, PUSDReward]);
};

export const useQueryInfoHelper = () => {
  const { address: account, chainID } = useWeb3Context();
  const InfoHelperContract = useInfoHelperContract();
  const data = useMemo(() => {
    return [ADDRESS[chainID].PID, account ? account : PLACEHOLDER_ACCOUNT];
  }, [account, chainID]);
  const { result } = useSingleCallResult(
    InfoHelperContract,
    "getBasicInfo",
    data,
    {
      gasRequired: 6000000,
    }
  );
  return useMemo(() => {
    if (!result) return;
    const userInfoArr = result[2];
    const rank = userInfoArr[10];
    return rank;
  }, [result]);
};

export const useQeuryUSDCCollateral = () => {
  const RewardRouterContract = useRewardRouterContract();
  const { chainID } = useWeb3Context();
  const tokens = getTokens(chainID);
  const { result } = useSingleCallResult(
    RewardRouterContract,
    "getPUSDCollateralDetail",
    undefined,
    { gasRequired: 3000000 }
  );
  return useMemo(() => {
    if (!result) return;
    const tokenAddressList = result[0];
    const tokenPriceList = result[1];
    const tokenPoolList = result[2];
    const collateralList: any = [];
    for (let i = 0; i < tokenAddressList.length; i++) {
      const currTokenArr = tokens.filter((token: { address: any }) =>
        compareAddress(tokenAddressList[i], token.address)
      );
      if (currTokenArr[0]) {
        const currToken = currTokenArr[0];
        const tokenAmount = ethers.utils.formatUnits(
          tokenPriceList[i],
          currTokenArr[0].decimals
        );
        const pool = ethers.utils.formatUnits(tokenPoolList[i], 30);
        const price = Number(pool) > 0 ? Number(pool) / Number(tokenAmount) : 0;
        const amount = Number(pool) > 0 ? Number(pool) / price : 0;
        collateralList.push({ amount, price, pool, ...currToken });
      }
    }

    return collateralList;
  }, [result, tokens]);
};

export const useQueryCircLocked = () => {
  const { chainID } = useWeb3Context();
  const PIKOContract = usePIKOContract();
  const totalSupply_vePIKO = useTotalSupply_vePIKO();
  const totalSupply_veesPIKO = useTotalSupply_veesPIKO();
  const totalStaked_PIKO = useTotalStaked_PIKO();
  const totalStaked_esPIKO = useTotalStaked_esPIKO();
  const LP_Farm_addr = ADDRESS[chainID].LP_Farm;
  const LP_Marketing_addr = ADDRESS[chainID].LP_Marketing;
  const Contracts_Reserve_addr = ADDRESS[chainID].Contracts_Reserve;
  const TokenVesting_PIKO_addr = ADDRESS[chainID].TokenVesting;
  const dis_token_addr = ADDRESS[chainID].dis_token;
  const PIKOStaking_addr = ADDRESS[chainID].PIKOStaking;
  const ido_addr = ADDRESS[chainID].IDO;
  const PIKO_burn_addr = ADDRESS[chainID].PIKO_burn;
  const PIKO_LP_addr = ADDRESS[chainID].PIKO_LP;
  const PIKO_DAO_addr = ADDRESS[chainID]?.PIKO_DAO;
  const { result: balance_LP_Farm_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [LP_Farm_addr]
  );
  const { result: LP_Marketing_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [LP_Marketing_addr]
  );
  const { result: Contracts_Reserve_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [Contracts_Reserve_addr]
  );
  const { result: TokenVesting_PIKO_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [TokenVesting_PIKO_addr]
  );
  const { result: dis_token_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [dis_token_addr]
  );
  const { result: PIKOStaking_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [PIKOStaking_addr]
  );
  const { result: ido_str } = useSingleCallResult(PIKOContract, "balanceOf", [
    ido_addr,
  ]);
  const { result: PIKO_burn_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [PIKO_burn_addr]
  );
  const { result: balance_PIKO_PIKO_BUSD_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [PIKO_LP_addr]
  );

  const { result: PIKO_DAO_balance_str } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [PIKO_DAO_addr]
  );
  const balance_PIKO_DAO = useMemo(() => {
    if (!PIKO_DAO_balance_str) return;
    return toFromBN(PIKO_DAO_balance_str[0]);
  }, [PIKO_DAO_balance_str]);
  const balance_LP_Farm = useMemo(() => {
    if (!balance_LP_Farm_str) return;
    return toFromBN(balance_LP_Farm_str[0]);
  }, [balance_LP_Farm_str]);
  const balance_PIKO_LP_Marketing = useMemo(() => {
    if (!LP_Marketing_str) return;
    return toFromBN(LP_Marketing_str[0]);
  }, [LP_Marketing_str]);

  const balance_PIKO_Contracts_Reserve = useMemo(() => {
    if (!Contracts_Reserve_str) return;
    return toFromBN(Contracts_Reserve_str[0]);
  }, [Contracts_Reserve_str]);

  const balance_PIKO_TokenVesting_PIKO = useMemo(() => {
    if (!TokenVesting_PIKO_str) return;
    return toFromBN(TokenVesting_PIKO_str[0]);
  }, [TokenVesting_PIKO_str]);

  const balance_PIKO_staking = useMemo(() => {
    if (!PIKOStaking_str) return;
    return toFromBN(PIKOStaking_str[0]);
  }, [PIKOStaking_str]);

  const balance_PIKO_dis_token = useMemo(() => {
    if (!dis_token_str) return;
    return toFromBN(dis_token_str[0]);
  }, [dis_token_str]);

  const balance_ido = useMemo(() => {
    if (!ido_str) return;
    return toFromBN(ido_str[0]);
  }, [ido_str]);

  const balance_PIKO_burn = useMemo(() => {
    if (!PIKO_burn_str) return;
    return toFromBN(PIKO_burn_str[0]);
  }, [PIKO_burn_str]);

  const balance_PIKO_PIKO_BUSD = useMemo(() => {
    if (!balance_PIKO_PIKO_BUSD_str) return;
    return toFromBN(balance_PIKO_PIKO_BUSD_str[0]);
  }, [balance_PIKO_PIKO_BUSD_str]);
  const circulatingSupply = useMemo(() => {
    if (
      !totalSupply_vePIKO ||
      !balance_PIKO_TokenVesting_PIKO ||
      !balance_LP_Farm ||
      !balance_PIKO_LP_Marketing ||
      !balance_PIKO_Contracts_Reserve ||
      !balance_PIKO_burn ||
      !balance_PIKO_dis_token ||
      !balance_PIKO_DAO
    )
      return;
    const data = totalSupply_vePIKO
      .minus(balance_PIKO_TokenVesting_PIKO)
      .minus(balance_LP_Farm)
      .minus(balance_PIKO_LP_Marketing)
      .minus(balance_PIKO_Contracts_Reserve)
      .minus(balance_PIKO_burn)
      .minus(balance_PIKO_dis_token)
      .minus(balance_PIKO_DAO);
    return data;
  }, [
    balance_PIKO_DAO,
    balance_LP_Farm,
    balance_PIKO_Contracts_Reserve,
    balance_PIKO_LP_Marketing,
    balance_PIKO_TokenVesting_PIKO,
    balance_PIKO_burn,
    balance_PIKO_dis_token,
    totalSupply_vePIKO,
  ]);

  const circLocked_PIKO = useMemo(() => {
    if (!totalStaked_PIKO || !circulatingSupply) return;
    return totalStaked_PIKO.div(circulatingSupply);
  }, [circulatingSupply, totalStaked_PIKO]);

  const circLocked_esPIKO = useMemo(() => {
    if (!totalStaked_esPIKO || !circulatingSupply) return;
    return totalStaked_esPIKO.div(circulatingSupply);
  }, [circulatingSupply, totalStaked_esPIKO]);

  const avgLockTime_PIKO = useMemo(() => {
    if (!totalSupply_vePIKO || !totalStaked_PIKO) return;
    return totalSupply_vePIKO.div(totalStaked_PIKO).minus(1).div(3).times(4);
  }, [totalStaked_PIKO, totalSupply_vePIKO]);

  const avgLockTime_esPIKO = useMemo(() => {
    if (!totalSupply_veesPIKO || !totalStaked_esPIKO) return;
    return totalSupply_veesPIKO
      .div(totalStaked_esPIKO)
      .minus(1)
      .div(3)
      .times(4);
  }, [totalSupply_veesPIKO, totalStaked_esPIKO]);

  return useMemo(() => {
    return {
      totalSupply_vePIKO,
      totalSupply_veesPIKO,
      totalStaked_PIKO,
      totalStaked_esPIKO,
      circulatingSupply,
      balance_PIKO_PIKO_BUSD,
      circLocked_PIKO,
      circLocked_esPIKO,
      avgLockTime_PIKO,
      avgLockTime_esPIKO,
    };
  }, [
    avgLockTime_PIKO,
    avgLockTime_esPIKO,
    balance_PIKO_PIKO_BUSD,
    circLocked_PIKO,
    circLocked_esPIKO,
    circulatingSupply,
    totalStaked_PIKO,
    totalStaked_esPIKO,
    totalSupply_vePIKO,
    totalSupply_veesPIKO,
  ]);
};
