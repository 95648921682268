import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";

import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import DialogBox from "../DialogBox";
import { ReactComponent as Setting } from "src/assets/images/setting2.svg";
import { ConfirmBtn } from "../Button/ConfirmBtn";
import SwitchesSize from "../Switchv5";
import { CancelBtn } from "../Button/CancelBtn";

const SettingDialogBox = styled.div`
  padding: 16px;
  .allowedBox {
    padding: 22px 16px;
    border-radius: 8px;
    background: #101010;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    .slippageAmount {
      padding-right: 16px;
      .amount-input {
        text-align: right;
        font-family: "Gilroy-Bold";
        color: ${({ theme }) => theme.text9};
      }
    }
  }
`;
type settingPros = {
  open: boolean;
  handleClose?: any;
  showPnlAfterFees?: any;
  setShowPnlAfterFees?: any;
  isPnlInLeverage?: any;
  setIsPnlInLeverage?: any;
  saveAndCloseSettings: any;
  slippageAmount: any;
  onInputValueChange: any;
};
export default function SettingDialog({
  open,
  handleClose,
  showPnlAfterFees,
  setShowPnlAfterFees,
  isPnlInLeverage,
  setIsPnlInLeverage,
  saveAndCloseSettings,
  slippageAmount,
  onInputValueChange,
}: settingPros) {
  return (
    <DialogBox
      width="480px"
      title="SETTING"
      icon={<Setting />}
      open={open}
      handleClose={handleClose}
    >
      <>
        <SettingDialogBox>
          <div className="allowedBox mt-16">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="slippageAmount"
            >
              <ThemedText.Text4Bold>
                <Trans>Allowed Slippage</Trans>
              </ThemedText.Text4Bold>
              <Box display="flex" alignItems="center">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="amount-input flex-1"
                  value={slippageAmount}
                  onChange={onInputValueChange}
                />
                <ThemedText.Text4Medium fontSize={20} ml="18px">
                  <Trans>%</Trans>
                </ThemedText.Text4Medium>
              </Box>
            </Box>
          </div>
          <Box display="flex" alignItems="center" className="pnlItem" mt="20px">
            <SwitchesSize
              isChecked={showPnlAfterFees}
              setIsChecked={setShowPnlAfterFees}
            />
            <ThemedText.Text9Medium fontSize={18} ml="16px">
              <Trans>Display PnL after fees</Trans>
            </ThemedText.Text9Medium>
          </Box>
          <Box display="flex" alignItems="center" className="pnlItem" mt="40px">
            <SwitchesSize
              isChecked={isPnlInLeverage}
              setIsChecked={setIsPnlInLeverage}
            />
            <ThemedText.Text9Medium fontSize={18} ml="16px">
              <Trans>Include PnL in leverage display</Trans>
            </ThemedText.Text9Medium>
          </Box>
        </SettingDialogBox>
        <Box
          display="flex"
          alignItems="center"
          className="operationBtn"
          p="40px 16px 16px "
        >
          <div className="flex-1">
            <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
          </div>
          <Box ml="16px" className="flex-1">
            <ConfirmBtn onClick={saveAndCloseSettings}>save</ConfirmBtn>
          </Box>
        </Box>
      </>
    </DialogBox>
  );
}
