import { Box } from "@material-ui/core";
import { Drawer, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemedText } from "src/theme/text";
import { ReactComponent as LogoIcon } from "src/assets/images/logo.svg";
import { ReactComponent as EarnIcon } from "src/assets/images/tabMenu/Earn.svg";
// import { ReactComponent as MoreIcon } from "src/assets/images/tabMenu/More.svg";
import { ReactComponent as DocsIcon } from "src/assets/images/links/docs.svg";
import { ReactComponent as StatsIcon } from "src/assets/images/links/stats.svg";
import { ReactComponent as SettingIcon } from "src/assets/images/links/setting.svg";
import { NavLink } from "react-router-dom";
import PriceM from "./PriceM";
import { useWeb3Context } from "src/hooks";

const DrawerBox = styled(Drawer)`
  .MuiDrawer-paper {
    background: #282828;
    width: 90vw;
  }
  a {
    text-decoration: none;
  }
  .logo-header {
    padding-left: 18px;
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
  .priceBox {
    padding: 16px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    .priceBorder {
      border-top: 1px solid rgba(255, 255, 255, 0.16);
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    }
  }
  .menuList-m {
    padding: 4px 16px;
    .menuItem {
      padding: 12px 0;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.08);
    }
  }
  .links-m {
    margin-bottom: 24px;
    .linkItem {
      margin-left: 32px;
    }
  }
`;

export const DrawerContainer = ({ showDrawer, toggleDrawer }: any) => {
  const { chainID } = useWeb3Context();
  return (
    <DrawerBox anchor={"left"} open={showDrawer} onClose={toggleDrawer}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <div className="flex-1">
          <Box display="flex" alignItems="center" className="logo-header ">
            <LogoIcon />
          </Box>
          <div className="priceBox">
            <div className="priceBorder">
              <PriceM />
            </div>
          </div>
          <div className="menuList-m">
            <Link component={NavLink} to={`/`} onClick={toggleDrawer}>
              <Box display="flex" alignItems="center" className="menuItem">
                <EarnIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Vesting
                </ThemedText.Text5Medium>
              </Box>
            </Link>
            {/* <Link component={NavLink} to={`/Trade`} onClick={toggleDrawer}>
              <Box display="flex" alignItems="center" className="menuItem">
                <TradeIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Trade
                </ThemedText.Text5Medium>
              </Box>
            </Link> */}
            {/* {chainID != 5001 && (
              <Link component={NavLink} to={`/Earn`} onClick={toggleDrawer}>
                <Box display="flex" alignItems="center" className="menuItem">
                  <EarnIcon />
                  <ThemedText.Text5Medium fontSize={18} ml="8px">
                    Earn
                  </ThemedText.Text5Medium>
                </Box>
              </Link>
            )} */}

            {/* <Link
              component={NavLink}
              to={`/Leaderboard`}
              onClick={toggleDrawer}
            >
              <Box display="flex" alignItems="center" className="menuItem">
                <LeaderBoardIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  LeaderBoard
                </ThemedText.Text5Medium>
              </Box>
            </Link> */}
            {/* <Box display="flex" alignItems="center" className="menuItem" 
                onClick={toggleDrawer}>
          <MoreIcon />
          <ThemedText.Text5Medium fontSize={18} ml="8px">
            More
          </ThemedText.Text5Medium>
        </Box> */}
          </div>
        </div>
        <Box display="flex" alignItems="center" className="links-m">
          <Box display="flex" alignItems="center" className="linkItem">
            <DocsIcon />
            <ThemedText.Text4Medium fontSize={14} ml="8px">
              Docs
            </ThemedText.Text4Medium>
          </Box>
          <Box display="flex" alignItems="center" className="linkItem">
            <StatsIcon />
            <ThemedText.Text4Medium fontSize={14} ml="8px">
              Stats
            </ThemedText.Text4Medium>
          </Box>
          <Box display="flex" alignItems="center" className="linkItem">
            <SettingIcon />
            <ThemedText.Text4Medium fontSize={14} ml="8px">
              Setting
            </ThemedText.Text4Medium>
          </Box>
        </Box>
      </Box>
    </DrawerBox>
  );
};
