import styled from "styled-components";

export const TableBox = styled.div`
  .tableHeader {
    padding-top: 26px;
    padding-bottom: 10px;
  }
  .tableTr {
    padding: 14px 0;
  }
  .tableHeader,
  .tableTr {
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
    .td {
      margin-left: 20px;
    }
  }
  .table-m {
    padding: 16px 12px;
    .tableHeader-m {
      height: 40px;
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
    .tableItem-m {
      padding: 10px 0;
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
  }
`;
