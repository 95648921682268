import { Trans } from "@lingui/macro";
import { Box, Skeleton } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { useSelector } from "react-redux";
import { AssetBox } from "../AssetList";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useVePIKOBalance,
  useVeesPIKOBalance,
} from "src/hooks/useQueryEarn";

const AssetMBox = styled(AssetBox)`
  margin-top: 24px;
  .assetItem-m {
    padding-bottom: 20px;
    .asset {
      padding: 16px 0;
      border-bottom: 1px dashed ${({ theme }) => theme.bg3};
    }
  }
`;

export default function AssetListM() {
  const PIKOPrice = useGetPikoPrice();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = useVePIKOBalance();
  const esPIKOBalance = useBalance_esPIKO();
  const esPIKOStaked = useVeesPIKOBalance();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  return (
    <AssetMBox className="">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="assetHeader-m"
      >
        <Box className="">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box className="text-right">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
      </Box>
      <div className="assetItem-m">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="asset"
        >
          <Box display="flex" alignItems="center" className="">
            <PIKO width={48} height={48} />
            <ThemedText.Text1Bold fontSize={24} ml="16px">
              <Trans>PIKO</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <div className="">
            <ThemedText.Text1Medium fontSize={16}>
              {formatAmount2(
                PIKOBalance?.plus(PIKOStaked ? PIKOStaked : 0),
                2,
                true
              )}
            </ThemedText.Text1Medium>
            <ThemedText.Text5 fontSize={12} mt="8px">
              $
              {formatAmount2(
                PIKOBalance?.plus(PIKOStaked ? PIKOStaked : 0).times(
                  PIKOPrice ? PIKOPrice : 1
                ),
                2,
                true
              )}
            </ThemedText.Text5>
          </div>
        </Box>
        <Box display="flex" alignItems="center" mt="16px">
          <Box display="flex" alignItems="center" className="">
            <ThemedText.Text4Bold>
              <Trans>Wallet</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(PIKOBalance, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className="text-right"
            ml="24px"
          >
            <ThemedText.Text4Bold>
              <Trans>Staked</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(PIKOStaked, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt="16px" className="">
          <Box className="flex-1">
            <BuyBtn>Earn</BuyBtn>
          </Box>
          <Box className="flex-1" ml="16px">
            <ClaimBtn>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </div>
      <div className="assetItem-m">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="asset"
        >
          <Box display="flex" alignItems="center" className="">
            <EsPIKO width={48} height={48} />
            <ThemedText.Text1Bold fontSize={24} ml="16px">
              <Trans>esPIKO</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <div className="">
            <ThemedText.Text1Medium fontSize={16}>
              {formatAmount2(
                esPIKOBalance?.plus(esPIKOStaked ? esPIKOStaked : 0),
                2,
                true
              )}
            </ThemedText.Text1Medium>
            <ThemedText.Text5 fontSize={12} mt="8px">
              $
              {formatAmount2(
                esPIKOBalance
                  ?.plus(esPIKOStaked ? esPIKOStaked : 0)
                  .times(PIKOPrice ? PIKOPrice : 1),
                2,
                true
              )}
            </ThemedText.Text5>
          </div>
        </Box>
        <Box display="flex" alignItems="center" mt="16px">
          <Box display="flex" alignItems="center" className="">
            <ThemedText.Text4Bold>
              <Trans>Wallet</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(esPIKOBalance, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className="text-right"
            ml="24px"
          >
            <ThemedText.Text4Bold>
              <Trans>Staked</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(esPIKOStaked, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt="16px" className="">
          <Box className="flex-1">
            <BuyBtn>Earn</BuyBtn>
          </Box>
          <Box className="flex-1" ml="16px">
            <ClaimBtn>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </div>
      {tokensArr?.length > 0 ? (
        tokensArr?.map((item, i) => (
          <div className="assetItem-m" key={i}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="asset"
            >
              <Box display="flex" alignItems="center" className="">
                <img src={item?.icon} width={48} height={48} />
                <ThemedText.Text1Bold fontSize={24} ml="16px">
                  {item?.name}
                </ThemedText.Text1Bold>
              </Box>
              <div className="">
                <ThemedText.Text1Medium fontSize={16}>
                  {formatAmount2(
                    item?.plpBalance * 1 + item?.plpBalanceStaked * 1,
                    2,
                    true
                  )}
                </ThemedText.Text1Medium>
                <ThemedText.Text5 fontSize={12} mt="8px">
                  $
                  {formatAmount2(
                    (item?.plpBalance * 1 + item?.plpBalanceStaked * 1) *
                      item?.price,
                    2,
                    true
                  )}
                </ThemedText.Text5>
              </div>
            </Box>
            <Box display="flex" alignItems="center" mt="16px">
              <Box display="flex" alignItems="center" className="">
                <ThemedText.Text4Bold>
                  <Trans>Wallet</Trans>
                </ThemedText.Text4Bold>
                <ThemedText.Text1Medium fontSize={16} ml="8px">
                  {formatAmount2(item?.plpBalance, 2, true)}
                </ThemedText.Text1Medium>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className="text-right"
                ml="24px"
              >
                <ThemedText.Text4Bold>
                  <Trans>Staked</Trans>
                </ThemedText.Text4Bold>
                <ThemedText.Text1Medium fontSize={16} ml="8px">
                  {formatAmount2(item?.plpBalanceStaked, 2, true)}
                </ThemedText.Text1Medium>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mt="16px" className="">
              <Box className="flex-1">
                <BuyBtn>Earn</BuyBtn>
              </Box>
              <Box className="flex-1" ml="16px">
                <ClaimBtn>Buy / Sell</ClaimBtn>
              </Box>
            </Box>
          </div>
        ))
      ) : (
        <div className="flex-1">
          <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
        </div>
      )}
    </AssetMBox>
  );
}
