import { Trans } from "@lingui/macro";
import { Box, Skeleton } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { useSelector } from "react-redux";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useVePIKOBalance,
  useVeesPIKOBalance,
} from "src/hooks/useQueryEarn";

export const AssetBox = styled(Box)`
  margin-top: 32px;
  .assetHeader,
  .assetItem {
    .td {
      margin-left: 12px;
    }
  }
  .assetHeader,
  .assetHeader-m {
    height: 40px;
  }
  .assetItem {
    padding: 18px 0;
  }
  /* .assetHeader,
  .assetHeader-m, */
  .assetItem,
  .assetItem-m {
    border-top: 1px solid ${({ theme }) => theme.bg4};
  }
`;

export default function AssetList() {
  const PIKOPrice = useGetPikoPrice();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = useVePIKOBalance();
  const esPIKOBalance = useBalance_esPIKO();
  const esPIKOStaked = useVeesPIKOBalance();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  return (
    <AssetBox className="assetList">
      <Box display="flex" alignItems="center" className="assetHeader">
        <ThemedText.Text4Bold className="flex-1 td">
          <Trans>Asset</Trans>
        </ThemedText.Text4Bold>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Wallet</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Staked</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <div className="flex-1 td"></div>
      </Box>
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <PIKO width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>PIKO</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(
              PIKOBalance?.plus(PIKOStaked ? PIKOStaked : 0),
              2,
              true
            )}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            $
            {formatAmount2(
              PIKOBalance?.plus(PIKOStaked ? PIKOStaked : 0).times(
                PIKOPrice ? PIKOPrice : 1
              ),
              2,
              true
            )}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(PIKOBalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(PIKOStaked, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <Box width="120px">
            <BuyBtn>Earn</BuyBtn>
          </Box>
          <Box width="120px" ml="16px">
            <ClaimBtn>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <EsPIKO width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>esPIKO</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(
              esPIKOBalance?.plus(esPIKOStaked ? esPIKOStaked : 0),
              2,
              true
            )}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            $
            {formatAmount2(
              esPIKOBalance
                ?.plus(esPIKOStaked ? esPIKOStaked : 0)
                .times(PIKOPrice ? PIKOPrice : 1),
              2,
              true
            )}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(esPIKOBalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(esPIKOStaked, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <Box width="120px">
            <BuyBtn>Earn</BuyBtn>
          </Box>
          <Box width="120px" ml="16px">
            <ClaimBtn>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </Box>
      {tokensArr?.length > 0 ? (
        tokensArr?.map((item, i) => (
          <Box display="flex" alignItems="center" className="assetItem" key={i}>
            <Box display="flex" alignItems="center" className="flex-1 td">
              <img src={item?.icon} width={48} height={48} />
              <ThemedText.Text1Bold fontSize={24} ml="16px">
                {item?.name}
              </ThemedText.Text1Bold>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(
                  item?.plpBalance * 1 + item?.plpBalanceStaked * 1,
                  2,
                  true
                )}
              </ThemedText.Text1Medium>
              <ThemedText.Text5 fontSize={12} mt="8px">
                $
                {formatAmount2(
                  (item?.plpBalance * 1 + item?.plpBalanceStaked * 1) *
                    item?.price,
                  2,
                  true
                )}
              </ThemedText.Text5>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(item?.plpBalance, 2, true)}
              </ThemedText.Text1Medium>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(item?.plpBalanceStaked, 2, true)}
              </ThemedText.Text1Medium>
            </Box>
            <Box display="flex" justifyContent="flex-end" className="flex-1 td">
              <Box width="120px">
                <BuyBtn>Earn</BuyBtn>
              </Box>
              <Box width="120px" ml="16px">
                <ClaimBtn>Buy / Sell</ClaimBtn>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <div className="flex-1">
          <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
        </div>
      )}
    </AssetBox>
  );
}
