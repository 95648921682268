import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useMemo } from "react";
import { SupportedChainId } from "src/constants/chainENV/chains.base";
import { useWeb3Context } from "src/hooks";

export const zk_test_graphPointUrl = `https://graph.pinnako.io/subgraphs/name/ekmanss/stats`;
export const zk_mainnet_graphPointUrl = `https://graph.pinnako.io/subgraphs/name/ekmanss/stats`;

export const ZKplp1Url = `https://graph.pinnako.io/subgraphs/name/ekmanss/stats`;
export const ZKplp2Url = `https://graph.pinnako.io/subgraphs/name/ekmanss/stats`;

export const zk_main_TVL = `https://api.studio.thegraph.com/query/49418/zkamin_tvl/version/latest`;
export const zk_test_TVL = `https://graph.pinnako.io/subgraphs/name/ekmanss/tvl`;

export function createClient(uri: string) {
  try {
    return new ApolloClient({
      uri,
      cache: new InMemoryCache(),
    });
  } catch (error) {}
}

export const graphChainIDUrl = {
  [SupportedChainId.ZKMAINNET]:
    "https://api.studio.thegraph.com/query/49418/zkmain_stats/version/latest",
  [SupportedChainId.ZKTEST]: zk_test_graphPointUrl,
};

export const graphTVLUrl = {
  [SupportedChainId.ZKMAINNET]: zk_main_TVL,
  [SupportedChainId.ZKTEST]: zk_test_TVL,
};

export function useVolumeChartGraphUrl() {
  const { chainID }: { chainID: SupportedChainId } = useWeb3Context();
  return useMemo(() => {
    return createClient(graphChainIDUrl[chainID]);
  }, [chainID]);
}

export function useTVLGraphUrl() {
  const { chainID }: { chainID: SupportedChainId } = useWeb3Context();
  return useMemo(() => {
    return createClient(graphTVLUrl[chainID]);
  }, [chainID]);
}
