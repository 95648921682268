import { Box, Skeleton } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { BN } from "src/utils/bn";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useVePIKOBalance,
  useVeesPIKOBalance,
} from "src/hooks/useQueryEarn";

const TokenListBox = styled(Box)`
  .balance {
    padding: 18px 8px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
  .tokenItem {
    padding: 18px 8px 14px;
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
  }
  .balance,
  .tokenItem {
    padding-left: 10px;
    padding-right: 6px;
  }
`;

export default function TokenList() {
  const PIKOPrice = useGetPikoPrice();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = useVePIKOBalance();
  const esPIKOBalance = useBalance_esPIKO();
  const esPIKOStaked = useVeesPIKOBalance();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  const totalAmount = useMemo(() => {
    if (
      PIKOBalance == undefined ||
      PIKOStaked == undefined ||
      esPIKOBalance == undefined ||
      esPIKOStaked == undefined ||
      PIKOPrice == undefined ||
      tokensArr.length == 0
    )
      return;
    const pikoAmount = PIKOBalance.plus(PIKOStaked).times(PIKOPrice);
    const espikoAmount = esPIKOBalance.plus(esPIKOStaked).times(PIKOPrice);
    const plpAmount = tokensArr.reduce((pre, curr) => {
      const { plpBalance, plpBalanceStaked, price } = curr;
      pre = pre.plus(BN(plpBalance).plus(plpBalanceStaked).times(price));
      return pre;
    }, BN(0));
    return plpAmount.plus(pikoAmount).plus(espikoAmount).toFixed();
  }, [
    PIKOBalance,
    PIKOStaked,
    esPIKOBalance,
    esPIKOStaked,
    PIKOPrice,
    tokensArr,
  ]);

  return (
    <TokenListBox className="tokenList">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="balance"
      >
        <ThemedText.Text4Bold>
          <Trans>Wallet</Trans>
        </ThemedText.Text4Bold>
        <ThemedText.Text4Bold>
          ${formatAmount2(totalAmount, 2, true)}
        </ThemedText.Text4Bold>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <PIKO />
          <ThemedText.Text9Bold ml="12px" fontSize={16}>
            PIKO
          </ThemedText.Text9Bold>
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(
              PIKOBalance?.plus(PIKOStaked ? PIKOStaked : 0),
              2,
              true
            )}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            $
            {formatAmount2(
              PIKOBalance?.plus(PIKOStaked ? PIKOStaked : 0).times(
                PIKOPrice ? PIKOPrice : 1
              ),
              2,
              true
            )}
          </ThemedText.Text4>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <EsPIKO />
          <ThemedText.Text9Bold ml="12px" fontSize={16}>
            esPIKO
          </ThemedText.Text9Bold>
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(
              esPIKOBalance?.plus(esPIKOStaked ? esPIKOStaked : 0),
              2,
              true
            )}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            $
            {formatAmount2(
              esPIKOBalance
                ?.plus(esPIKOStaked ? esPIKOStaked : 0)
                .times(PIKOPrice ? PIKOPrice : 1),
              2,
              true
            )}
          </ThemedText.Text4>
        </div>
      </Box>
      {tokensArr.length > 0 ? (
        tokensArr.map((item, i) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="tokenItem"
            key={i}
          >
            <Box display="flex" alignItems="center">
              <img src={item.icon} />
              <ThemedText.Text9Bold ml="12px" fontSize={16}>
                {item?.name}
              </ThemedText.Text9Bold>
            </Box>
            <div className="text-right">
              <ThemedText.Text9Bold fontSize={16}>
                {formatAmount2(
                  item?.plpBalance * 1 + item?.plpBalanceStaked * 1,
                  2,
                  true
                )}
              </ThemedText.Text9Bold>
              <ThemedText.Text4 fontSize={14}>
                $
                {formatAmount2(
                  (item?.plpBalance * 1 + item?.plpBalanceStaked * 1) *
                    item?.price,
                  2,
                  true
                )}
              </ThemedText.Text4>
            </div>
          </Box>
        ))
      ) : (
        <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
      )}
    </TokenListBox>
  );
}
