import { Box, Link, styled } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks";
import Close from "../../assets/images/close.svg";
import Close2 from "../../assets/images/close2.svg";
import Networks from "../../assets/images/networks.svg";
import { ReactComponent as Down } from "../../assets/images/down.svg";
import Errors from "../../assets/images/error_.svg";
import { ReactComponent as Wallet } from "../../assets/images/header/wallet.svg";
import Logo from "../../assets/images/logo.svg";
import logoM from "../../assets/images/logoM.svg";
import Menu from "../../assets/images/menu.svg";
import { ReactComponent as Setting } from "../../assets/images/setting.svg";
import { ReactComponent as SettingM } from "../../assets/images/setting-m.svg";
import { shorten } from "../../helpers";
import useClaim from "../../hooks/claim";
import "../../views/dashboard/dialog.scss";
import Checkbox from "../Checkbox/Checkbox2";
import "./header.scss";
import { toastSuccess } from "src/helpers/toastHelpers";
import { SupportedChainId } from "src/constants/chains";
import MenuListComposition from "src/components/Menu";
import PikoPrice from "src/components/PikoPrice";
import { DrawerContainer } from "./DrawerContainer";
import { BuyOrSellBtn } from "../Button/BuyOrSellBtn";
import ChainBtn from "./ChainBtn";
import Account from "./Account";
import ChainBox from "./ChainBox";
import AccountBox from "./AccountBox";
import MoreItem from "./MoreItem";
import DialogBox from "../DialogBox";
import SettingDialog from "../SettingDialog";
import PriceM from "./PriceM";
const LinkBox = styled(Link)`
  .muilink-underlinehover:hover {
    text-decoration: none;
  }
`;

export default function Header(props) {
  const {
    slippageAmount,
    onInputValueChange,
    saveSettings,
    showPnlAfterFees,
    setShowPnlAfterFees,
    isPnlInLeverage,
    setIsPnlInLeverage,
    openSettings,
    showSetting,
    setShowSetting,
  } = props;
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const history = useHistory();

  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const { connect, connected, web3, address, chainID, switchNetwork2, Error } =
    useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  const handleClose = () => {
    setShowSetting(false);
  };
  const saveAndCloseSettings = () => {
    saveSettings();
  };

  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  const { claimTokens } = useClaim();
  const getTokens = async () => {
    await claimTokens();
  };

  const handlerNetwork = () => {
    setShow(true);
  };

  return (
    <div className="header">
      {isVerySmallScreen && <PriceM />}
      <Box display="flex" alignItems="center" className="header-view">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="header"
        >
          <Box display="flex" alignItems="center" className="" height={"100%"}>
            {isSmallScreen && (
              <img src={Menu} alt="menu" onClick={toggleDrawer} />
            )}
            <img
              src={isVerySmallScreen ? logoM : Logo}
              alt="logo"
              className="logo"
            />
            {!isSmallScreen && (
              <Box
                display="flex"
                className="menuList font-14 font-weight-6"
                height={"100%"}
                gridGap={36}
                alignItems={"center"}
              >
                {/* <LinkBox
                  component={NavLink}
                  to={`/`}
                  className={`menuItem ${pathname == "/" && "menuItemActive"}`}
                >
                  Dashboard
                </LinkBox>
                <LinkBox
                  component={NavLink}
                  to={`/Trade`}
                  className={`menuItem ${
                    pathname == "/Trade" && "menuItemActive"
                  }`}
                >
                  Trade
                </LinkBox>
                {chainID != 5001 && (
                  <LinkBox
                    component={NavLink}
                    to={`/Earn`}
                    className={`menuItem ${
                      pathname == "/Earn" && "menuItemActive"
                    }`}
                  >
                    Earn
                  </LinkBox>
                )}
                <LinkBox
                  component={NavLink}
                  to={`/DAO`}
                  className={`menuItem ${
                    pathname == "/DAO" && "menuItemActive"
                  }`}
                >
                  DAO
                </LinkBox> */}
                <LinkBox
                  component={NavLink}
                  to={`/`}
                  className={`menuItem ${pathname == "/" && "menuItemActive"}`}
                >
                  Vesting
                </LinkBox>
                {/* <LinkBox
                  component={NavLink}
                  to={`/Leaderboard`}
                  className={`menuItem ${
                    pathname == "/Leaderboard" && "menuItemActive"
                  }`}
                >
                  Leaderboard
                </LinkBox> */}
                {/* <Box className="menuItem">
                  <MoreItem />
                </Box> */}
              </Box>
            )}
          </Box>
          {isSmallScreen ? (
            <Box display="flex" alignItems="center" className="header-r">
              <ChainBox />
              <AccountBox />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" className="header-r">
              {chainID == 280 && (
                <BuyOrSellBtn onClick={getTokens}>Faucet</BuyOrSellBtn>
              )}
              {/* <Box display="flex" alignItems="center" className="ml-12">
                <PikoPrice />
              </Box> */}
              <ChainBox />
              <AccountBox />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                ml="16px"
                onClick={openSettings}
                className="pointer"
              >
                <Setting className="setting" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <DrawerContainer showDrawer={showDrawer} toggleDrawer={toggleDrawer} />
      <SettingDialog
        open={showSetting}
        handleClose={handleClose}
        showPnlAfterFees={showPnlAfterFees}
        setShowPnlAfterFees={setShowPnlAfterFees}
        isPnlInLeverage={isPnlInLeverage}
        setIsPnlInLeverage={setIsPnlInLeverage}
        saveAndCloseSettings={saveAndCloseSettings}
        slippageAmount={slippageAmount}
        onInputValueChange={onInputValueChange}
      />
    </div>
  );
}
