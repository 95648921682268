import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import AssetList from "./AssetList";
import { useMemo } from "react";
import { formatAmount2 } from "src/helpers/Helpers";
import { useSelector } from "react-redux";
import { BN } from "src/utils/bn";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import AssetListM from "./AssetListM";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useVePIKOBalance,
  useVeesPIKOBalance,
} from "src/hooks/useQueryEarn";

const AssetBox = styled(Box)`
  width: 100%;
  .titleBox {
    padding-bottom: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.bg4};
  }
`;

export default function Asset() {
  const isSmallScreen = useMediaQuery("(max-width: 1180px)");
  const PIKOPrice = useGetPikoPrice();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = useVePIKOBalance();
  const esPIKOBalance = useBalance_esPIKO();
  const esPIKOStaked = useVeesPIKOBalance();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  const totalAmount = useMemo(() => {
    if (
      PIKOBalance == undefined ||
      PIKOStaked == undefined ||
      esPIKOBalance == undefined ||
      esPIKOStaked == undefined ||
      PIKOPrice == undefined ||
      tokensArr.length == 0
    )
      return;
    const pikoAmount = PIKOBalance.plus(PIKOStaked).times(PIKOPrice);
    const espikoAmount = esPIKOBalance.plus(esPIKOStaked).times(PIKOPrice);
    const plpAmount = tokensArr.reduce((pre, curr) => {
      const { plpBalance, plpBalanceStaked, price } = curr;
      pre = pre.plus(BN(plpBalance).plus(plpBalanceStaked).times(price));
      return pre;
    }, BN(0));

    return plpAmount.plus(pikoAmount).plus(espikoAmount);
  }, [
    PIKOBalance,
    PIKOStaked,
    esPIKOBalance,
    esPIKOStaked,
    PIKOPrice,
    tokensArr,
  ]);
  return (
    <AssetBox className="asset-view">
      <Box display="flex" justifyContent="center" className="titleBox">
        <div className="container">
          <ThemedText.Title>
            <Trans>Asset</Trans>
          </ThemedText.Title>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="36px"
          >
            <div className="">
              <ThemedText.Text4Bold>
                <Trans>Total Value</Trans>
              </ThemedText.Text4Bold>
              <ThemedText.Text1Bold mt="2px" fontSize={36}>
                ${formatAmount2(totalAmount, 2, true)}
              </ThemedText.Text1Bold>
            </div>
            {/* <TokensPercentage /> */}
          </Box>
        </div>
      </Box>
      <Box display="flex" justifyContent="center">
        <div className="container">
          {isSmallScreen ? <AssetListM /> : <AssetList />}
        </div>
      </Box>
    </AssetBox>
  );
}
