import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as ArrowDropDownIcon } from "src/assets/images/downicon.svg";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { Menu } from "@headlessui/react";
import { DOC_LINK } from "src/constants/misc";

const MoreBox = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  .menuBtn {
    background: transparent;
    border: none;
  }
  .linkList {
    position: absolute;
    top: 3rem;
    right: 0;
    padding: 8px 12px;
    background: #161616;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    .linkItem {
      padding: 12px 0;
      display: block;
      :not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

export default function MoreItem() {
  const links = [
    {
      link: DOC_LINK.stats,
      name: "Stats",
      target: "_blank",
    },
    {
      link: "#/Governance",
      name: "Governance",
      target: "",
    },
    {
      link: DOC_LINK.documentation,
      name: "Documentation",
      target: "_blank",
    },
    {
      link: DOC_LINK.discord,
      name: "Discord",
      target: "_blank",
    },
    {
      link: DOC_LINK.twitter,
      name: "Twitter",
      target: "_blank",
    },
    {
      link: DOC_LINK.telegram,
      name: "Telegram",
      target: "_blank",
    },
    {
      link: DOC_LINK.medium,
      name: "Medium",
      target: "_blank",
    },
    {
      link: DOC_LINK.github,
      name: "Github",
      target: "_blank",
    },
  ];
  return (
    <MoreBox>
      <Menu>
        <Menu.Button className="menuBtn">
          <Box display="flex" alignItems="center">
            <ThemedText.Text4Medium mr="8px">
              <Trans>more</Trans>
            </ThemedText.Text4Medium>
            <ArrowDropDownIcon />
          </Box>
        </Menu.Button>
        <div className="">
          <Menu.Items as="div" className="linkList">
            {links.map((item) => (
              <Menu.Item
                as="a"
                key={item.name}
                href={item.link}
                className="linkItem"
              >
                <div>{item.name}</div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </div>
      </Menu>
    </MoreBox>
  );
}
