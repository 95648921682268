import { setAll } from "../helpers";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { addresses } from "../configs/constants";
import { ethers } from "ethers";
import { abi as DisTokenABI } from "../abis/DisToken.json";
import { abi as PIKOStakeABI } from "../abis/PIKOStake.json";
import moment from "moment";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { getPikoPrice } from "src/hooks/get_price_piko";

const getEndTime = async (
  networkID: number,
  address: string,
  signer: ethers.Signer | ethers.providers.Provider | undefined
) => {
  const vePIKOContract = new ethers.Contract(
    addresses[networkID]?.vePIKO,
    PIKOStakeABI,
    signer
  );
  const lockedInfo = await vePIKOContract.locked(
    address || PLACEHOLDER_ACCOUNT
  );
  const currtimestamp = moment().valueOf();
  const currtimestampS = (currtimestamp / 1000).toFixed(0);
  const endTime = Number(lockedInfo[1]) - Number(currtimestampS);
  return endTime;
};

export const loadEarnDetails = createAsyncThunk(
  "earn/loadEarn",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    // console.log("loadEarnDetails");

    const vePIKOContract = new ethers.Contract(
      addresses[networkID]?.vePIKO,
      PIKOStakeABI,
      provider
    );
    const vePIKOContractold = new ethers.Contract(
      addresses[networkID]?.oldvePIKO,
      PIKOStakeABI,
      provider
    );
    const vePIKOBalance = Number(
      ethers.utils.formatUnits(
        await vePIKOContract.balanceOfAtNow(address || PLACEHOLDER_ACCOUNT),
        18
      )
    );

    const allvePIKOBalance = vePIKOBalance;

    const DisTokenContract = new ethers.Contract(
      addresses[networkID]?.dis_token,
      DisTokenABI,
      provider
    );

    const weekRewards_piko = Number(
      ethers.utils.formatUnits(await DisTokenContract.getYieldForDuration(), 18)
    );
    const DisFeeContract = new ethers.Contract(
      addresses[networkID]?.dis_fee,
      DisTokenABI,
      provider
    );

    const weekRewards_usdc = Number(
      ethers.utils.formatUnits(await DisFeeContract.getYieldForDuration(), 18)
    );

    const totalSupply_vePIKO = Number(
      ethers.utils.formatUnits(await vePIKOContract.totalSupplyAtNow(), 18)
    );

    const totalSupply_vePIKO_old = Number(
      ethers.utils.formatUnits(await vePIKOContractold.totalSupplyAtNow(), 18)
    );

    const all_totalSupply_vePIKO = totalSupply_vePIKO + totalSupply_vePIKO_old;

    const PIKOPrice = await getPikoPrice({ networkID, provider });

    let pikoApr1 = 0;
    let pikoApr2 = 0;
    let usdcApr1 = 0;
    let usdcApr2 = 0;
    // console.log("weekRewards_usdc", weekRewards_usdc, "totalSupply_vePIKO", totalSupply_vePIKO)
    if (weekRewards_piko > 0 && all_totalSupply_vePIKO > 0) {
      pikoApr1 = ((weekRewards_piko / 7) * 365) / all_totalSupply_vePIKO; //*(1 + 3 / 4 / 365 * 7)
      pikoApr2 = (((weekRewards_piko / 7) * 365) / all_totalSupply_vePIKO) * 4;
    }
    if (weekRewards_usdc > 0 && all_totalSupply_vePIKO > 0) {
      usdcApr1 =
        ((weekRewards_usdc / PIKOPrice / 7) * 365) / all_totalSupply_vePIKO; //* (1 + 3 / 4 / 365 * 7)
      usdcApr2 =
        (((weekRewards_usdc / PIKOPrice / 7) * 365) / all_totalSupply_vePIKO) *
        4;
    }
    // console.log("pikoApr1", pikoApr1, "pikoApr2", pikoApr2, "usdcApr1", usdcApr1, "usdcApr2", usdcApr1)
    const minApr = pikoApr1 + usdcApr1;
    const maxApr = pikoApr2 + usdcApr2;

    const lockedInfo = await vePIKOContract.locked(
      address || PLACEHOLDER_ACCOUNT
    );
    const PIKOStakedAmount = Number(
      ethers.utils.formatUnits(lockedInfo[0], 18)
    );
    const pikoApr = (allvePIKOBalance / PIKOStakedAmount) * pikoApr1; // / (1 + 3 / 4 / 365 * 7)
    const usdcApr = (allvePIKOBalance / PIKOStakedAmount) * usdcApr1; // / (1 + 3 / 4 / 365 * 7)
    const estApr = pikoApr + usdcApr;

    const endTime = await getEndTime(
      networkID,
      address || PLACEHOLDER_ACCOUNT,
      provider
    );

    return {
      vePIKOBalance: allvePIKOBalance,
      pikoApr1,
      pikoApr2,
      usdcApr1,
      usdcApr2,
      minApr,
      maxApr,
      pikoApr,
      usdcApr,
      estApr,
      endTime,
    };
  }
);

const initialState = {
  loading: false,
};

const earnSlice = createSlice({
  name: "earn",
  initialState,
  reducers: {
    fetchEarnSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEarnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadEarnDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadEarnDetails.rejected, (state, {}) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.earn;
export default earnSlice.reducer;
export const { fetchEarnSuccess } = earnSlice.actions;
export const getEarnState = createSelector(baseInfo, (earn) => earn);
