import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ContainerDiv = styled.div<{ rpt?: string; showBorder?: boolean }>`
  display: flex;
  .leftTab {
    width: 272px;
    padding: 16px;
    background: #0d0d0d;
  }
  .rightContainer {
    padding-top: ${({ rpt }) => (rpt ? rpt : "40px")};
    /* border-left: ${({ showBorder }) =>
      showBorder ? "1px solid rgba(255, 255, 255, 0.12)" : "none"}; */
  }
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    .leftTab {
      width: 100%;
      padding: 16px 16px 0 16px;
    }
    .rightContainer {
      width: 100%;
      padding-top: 24px;
    }
  }
`;
type dataPros = {
  leftContainer?: React.ReactNode;
  rightContainer?: React.ReactNode;
  rpt?: string;
  showBorder?: boolean;
};

export default function ContainerBox({
  leftContainer,
  rightContainer,
  rpt,
  showBorder,
}: dataPros) {
  return (
    <ContainerDiv rpt={rpt} showBorder={showBorder}>
      <div className="leftTab">{leftContainer}</div>
      <Box
        display="flex"
        justifyContent="center"
        className="rightContainer flex-1"
      >
        {rightContainer}
      </Box>
    </ContainerDiv>
  );
}
