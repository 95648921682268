import { Box, Menu, useMediaQuery } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as Dropdown } from "src/assets/images/dropdown2.svg";
import React, { useEffect, useState } from "react";
import { shorten } from "src/helpers";
import { useWeb3Context } from "src/hooks";
import { ConnectBtn } from "./ConnectBtn";
import Account from "./Account";

const MenuBox = styled(Menu)`
  .MuiPaper-root {
    padding: 0;
    background: ${({ theme }) => theme.bg11};
    border: 1px solid ${({ theme }) => theme.bg4};
    border-radius: 4px;
  }
  .MuiMenu-paper {
    min-width: 360px;
  }
  .MuiMenuItem-root {
    height: 40px;
    min-height: 40px;
    padding: 0 12px;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
    &:hover {
      background: ${({ theme }) => theme.bg2};
    }
  }

  @media only screen and (max-width: 680px) {
    .MuiMenu-paper {
      min-width: 300px;
    }
  }
  @media only screen and (max-width: 321px) {
    .MuiMenu-paper {
      min-width: 278px;
    }
  }
`;

const AccountDiv = styled(Box)`
  margin-left: 12px;
`;

export default function AccountBox() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const { connect, connected, address } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  let buttonText = isSmallScreen ? "Connect" : "Connect Wallet";
  if (isConnected) {
    buttonText = shorten(address);
  }
  useEffect(() => {
    setConnected(connected);
  }, [connected]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
      console.log("anchorEl", anchorEl);
    }, 0);
  };

  return (
    <AccountDiv>
      <ConnectBtn
        className="pointer"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={isConnected ? handleClick : connect}
      >
        {buttonText}
        {isConnected && <Dropdown className="downIcon" />}
      </ConnectBtn>
      <MenuBox
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Account handleClose={handleClose} />
      </MenuBox>
    </AccountDiv>
  );
}
